//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { setProductVersionParams } from "../../features/productVersions/productVersionsSlice";
import { SortingStateOption } from "../../models/sorting";
import { useTranslation } from "react-i18next";
import MainTableHeaderCell from "../_layout/MainTableHeaderCell";
//#endregion

export default function ProductVersionsTableHeader() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { product_version_params } = useAppSelector(
    (state) => state.productVersions
  );
  //#endregion

  //#region LOGIC

  // Set order sorting
  const sortChangedHandler = (option: SortingStateOption) => {
    if (option.column !== null) {
      dispatch(
        setProductVersionParams({
          sort: JSON.stringify(option),
        })
      );
    } else {
      dispatch(setProductVersionParams({ sort: undefined }));
    }
  };
  //#endregion

  return (
    <thead>
      <tr>
        {/* read */}
        <th
          scope="col"
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs font-semibold text-gray-900"
        />
        {/* number */}
        <MainTableHeaderCell
          headerText={t("rituals.code")}
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
        />

        {/* name */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          headerText={t("rituals.name")}
          sortName="name"
          params={product_version_params}
        />

        {/* language */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.language")}
          sortName="language"
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
          params={product_version_params}
        />

        {/* produced_by */}
        <MainTableHeaderCell
          headerText={t("rituals.producer")}
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
        />

        {/* Delete */}
        <th
          scope="col"
          className="whitespace-nowrap px-2 w-16 py-3.5 text-left text-xs font-semibold text-gray-900"
        />
      </tr>
    </thead>
  );
}
