//#region IMPORTS
import { ReactNode } from "react";
import { MetaData } from "../../models/pagination";
import LoadingOverlay from "../loading/LoadingOverlay";
import LoadingSpinner from "../loading/LoadingSpinner";
import PaginationBar from "../paginationBar/PaginationBar";
//#endregion

//#region INTERFACE
interface Props {
  onPageChangeHandler?: (page: number) => void;
  itemsLoaded: boolean;
  children: ReactNode;
  metaData?: MetaData;
  pageType?: string;
  header: ReactNode;
  hide?: boolean;
}
//#endregion

export default function MainTable({
  onPageChangeHandler,
  itemsLoaded,
  children,
  metaData,
  pageType = "items",
  header,
  hide = false,
}: Props) {
  return !hide ? (
    <div className="relative">
      {!itemsLoaded && <LoadingOverlay />}
      <table className="min-w-full divide-y divide-gray-300">
        {header}
        <tbody className="bg-white">{children}</tbody>
      </table>
      <PaginationBar
        metaData={metaData}
        onPageChange={(page: number) =>
          onPageChangeHandler && onPageChangeHandler(page)
        }
        pageType={pageType}
        hide={!metaData}
      />
    </div>
  ) : (
    <LoadingSpinner />
  );
}
