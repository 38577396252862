//#region IMPORTS
import {
  setUserParams,
  clearUserParam,
  clearUserParams,
} from "../../features/users/usersSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import MainFilterBarItem from "../_layout/MainFilterBarItem";
import MainFilterBar from "../_layout/MainFilterBar";
//#endregion

export default function UsersFilterBar() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { page, sort, limit, ...params } = useAppSelector(
    (state) => state.users.user_params
  );
  const { filters, filters_loaded } = useAppSelector((state) => state.users);
  //#endregion

  //#region RENDER
  return (
    <MainFilterBar
      paramNamesList={params}
      clearAllFiltersHandler={() => dispatch(clearUserParams())}
      clearFilterHandler={(paramName: string) => {
        console.log(paramName);
        dispatch(clearUserParam(paramName));
      }}
      disabled={!filters_loaded}
    >
      {/* name */}
      <MainFilterBarItem
        filterParameterName="name"
        displayExplanation="Search the users by name"
        clearFilterAction={() => dispatch(clearUserParam("name"))}
        selectedValue={params.name}
        filterAction={(value: string) =>
          dispatch(setUserParams({ name: value }))
        }
        displayText="User name"
        selected={!!params.name}
      />

      {/* email */}
      <MainFilterBarItem
        filterParameterName="email"
        displayExplanation="Search the users by email"
        clearFilterAction={() => dispatch(clearUserParam("email"))}
        selectedValue={params.email}
        filterAction={(value: string) =>
          dispatch(setUserParams({ email: value }))
        }
        displayText="User email"
        selected={!!params.email}
      />

      {/* external_store_id */}
      <MainFilterBarItem
        filterParameterName="external_store_id"
        displayExplanation="Search the users by store number"
        clearFilterAction={() => dispatch(clearUserParam("external_store_id"))}
        selectedValue={params.external_store_id}
        filterAction={(value: string) =>
          dispatch(setUserParams({ external_store_id: value }))
        }
        displayText="Store number"
        selected={!!params.external_store_id}
      />

      {/* store_name */}
      <MainFilterBarItem
        filterParameterName="store_name"
        displayExplanation="Filter the users by store name"
        clearFilterAction={() => dispatch(clearUserParam("store_name"))}
        selectBoxItems={filters.store_name.filter(
          (item) => item !== null && item !== "-"
        )}
        selectedValue={params.store_name}
        filterAction={(value: string) => {
          dispatch(setUserParams({ store_name: value }));
        }}
        displayText="Store name"
        selectBox
        selected={!!params.store_name}
      />

      {/* store_country_name */}
      <MainFilterBarItem
        filterParameterName="store_country_name"
        displayExplanation="Filter the users by country"
        clearFilterAction={() => dispatch(clearUserParam("store_country_name"))}
        selectBoxItems={filters.store_country_name.filter(
          (item) => item !== null && item !== "-"
        )}
        selectedValue={params.store_country_name}
        filterAction={(value: string) => {
          dispatch(setUserParams({ store_country_name: value }));
        }}
        displayText="Country"
        selectBox
        selected={!!params.store_country_name}
      />

      {/* language */}
      <MainFilterBarItem
        filterParameterName="language"
        displayExplanation="Filter the users by language"
        clearFilterAction={() => dispatch(clearUserParam("language"))}
        selectBoxItems={filters.language.filter(
          (item) => item !== null && item !== "-"
        )}
        selectedValue={params.language}
        filterAction={(value: string) => {
          dispatch(setUserParams({ language: value }));
        }}
        displayText="Language"
        selectBox
        selected={!!params.language}
      />
    </MainFilterBar>
  );
  //#endregion
}
