//#region IMPORTS
import {
  setProductVersionParams,
  clearProductVersionParam,
  clearProductVersionParams,
} from "../../features/productVersions/productVersionsSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import MainFilterBarItem from "../_layout/MainFilterBarItem";
import MainFilterBar from "../_layout/MainFilterBar";
//#endregion

export default function ProductVersionsFilterBar() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { page, limit, sort, ...params } = useAppSelector(
    (state) => state.productVersions.product_version_params
  );
  const { filters, filters_loaded } = useAppSelector(
    (state) => state.productVersions
  );
  //#endregion

  //#region RENDER
  return (
    <MainFilterBar
      paramNamesList={params}
      clearAllFiltersHandler={() => dispatch(clearProductVersionParams())}
      clearFilterHandler={(paramName: string) => {
        dispatch(clearProductVersionParam(paramName));
      }}
      disabled={!filters_loaded}
    >
      {/* name */}
      <MainFilterBarItem
        filterParameterName="product_name"
        displayExplanation="Search the products versions by name"
        clearFilterAction={() =>
          dispatch(clearProductVersionParam("product_name"))
        }
        selectedValue={params.product_name}
        filterAction={(value: string) =>
          dispatch(setProductVersionParams({ product_name: value }))
        }
        displayText="Product name"
        selected={!!params.product_name}
      />

      {/* number */}
      <MainFilterBarItem
        filterParameterName="number"
        displayExplanation="Search the products versions by code"
        clearFilterAction={() => dispatch(clearProductVersionParam("number"))}
        selectedValue={params.number}
        filterAction={(value: string) =>
          dispatch(setProductVersionParams({ number: value }))
        }
        displayText="Code"
        selected={!!params.number}
      />

      {/* promo_status */}
      <MainFilterBarItem
        filterParameterName="city"
        displayExplanation="Filter the products versions by promo status"
        clearFilterAction={() =>
          dispatch(clearProductVersionParam("promo_status"))
        }
        selectBoxItems={filters.promo_status.filter((item) => item !== null)}
        selectedValue={params.promo_status}
        filterAction={(value: string) => {
          dispatch(setProductVersionParams({ promo_status: value }));
        }}
        displayText="Promo status"
        selectBox
        selected={!!params.promo_status}
      />

      {/* produced_by */}
      <MainFilterBarItem
        filterParameterName="city"
        displayExplanation="Filter the products versions by producer"
        clearFilterAction={() =>
          dispatch(clearProductVersionParam("produced_by"))
        }
        selectBoxItems={filters.produced_by.filter((item) => item !== null)}
        selectedValue={params.produced_by}
        filterAction={(value: string) => {
          dispatch(setProductVersionParams({ produced_by: value }));
        }}
        displayText="Produced by"
        selectBox
        selected={!!params.produced_by}
      />

      {/* language */}
      <MainFilterBarItem
        filterParameterName="city"
        displayExplanation="Filter the products versions by language"
        clearFilterAction={() => dispatch(clearProductVersionParam("language"))}
        selectBoxItems={filters.language.filter((item) => item !== null)}
        selectedValue={params.language}
        filterAction={(value: string) => {
          dispatch(setProductVersionParams({ language: value }));
        }}
        displayText="Language"
        selectBox
        selected={!!params.language}
      />

      {/* promo_code */}
      <MainFilterBarItem
        filterParameterName="promo_code"
        displayExplanation="Filter the products versions by project"
        clearFilterAction={() =>
          dispatch(clearProductVersionParam("promo_code"))
        }
        selectBoxItems={filters.promo_id.filter((item) => item !== null)}
        selectedValue={params.promo_code}
        filterAction={(value: string) => {
          dispatch(setProductVersionParams({ promo_code: value }));
        }}
        displayText="Project"
        selectBox
        selected={!!params.promo_code}
      />
    </MainFilterBar>
  );
  //#endregion
}
