//#region IMPORTS
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { Tooltip } from "react-tooltip";
import { Order } from "../../models/order";
//#endregion

//#region INTERFACE
interface Props {
  approveHandler: () => void;
  hide?: boolean;
  order: Order;
}
//#endregion

export default function OrdersTableRowActionApprove({
  approveHandler,
  order,
  hide = false,
}: Props) {
  //#region SETUP
  return !hide ? (
    <Fragment>
      <button
        data-tooltip-content="Approve order?"
        data-tooltip-variant="dark"
        data-tooltip-place="left"
        data-tooltip-id={String(order.id) + "approve"}
        data-tooltip-delay-show={500}
        className="flex items-center gap-4"
      >
        {order.approved ? (
          <HandThumbUpIcon
            className="h-5 w-5 text-emerald-500 hover:cursor-pointer hover:text-emerald-400"
            onClick={approveHandler}
            type="button"
          />
        ) : (
          <HandThumbDownIcon
            className="h-5 w-5 text-red-500 hover:cursor-pointer hover:text-red-400"
            onClick={approveHandler}
            type="button"
          />
        )}
      </button>
      <Tooltip id={String(order.id) + "approve"} />
    </Fragment>
  ) : null;
}
