//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { fetchProductsAsync } from "./productsSlice";
import { useEffect } from "react";
import ProductsContainer from "../../components/products/ProductsContainer";
//#endregion

export default function ProductsPage() {
  //#region SETUP
  // Redux state
  const dispatch = useAppDispatch();
  const { products_loaded } = useAppSelector((state) => state.products);
  //#endregion

  //#region LOGIC
  // Load  products
  useEffect(() => {
    const fetchData = async () => {
      if (!products_loaded) {
        await dispatch(fetchProductsAsync());
      }
    };
    fetchData();
  }, [products_loaded, dispatch]);
  //#endregion

  //#region RENDER
  return <ProductsContainer />;
  //#endregion
}
