//#region IMPORTS
import classNames from "../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  selected?: boolean;
  linkName: string;
  dashOpen?: boolean;
}

//#endregion

export default function DashboardSidebarListItemText({
  selected,
  linkName,
  dashOpen,
}: Props) {
  //#region RENDER
  return dashOpen ? (
    <span className={classNames(selected ? "font-bold text-lime-900" : "")}>
      {linkName}
    </span>
  ) : null;
  //#endregion
}
