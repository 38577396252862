//#region IMPORTS
import { XCircleIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { Tooltip } from "react-tooltip";
import { Order } from "../../models/order";
//#endregion

//#region INTERFACE
interface Props {
  cancelHandler: () => void;
  hide?: boolean;
  order: Order;
}
//#endregion

export default function OrdersTableRowActionCancel({
  cancelHandler,
  order,
  hide = false,
}: Props) {
  //#region SETUP
  return !hide ? (
    <Fragment>
      <button
        data-tooltip-content="Cancel order?"
        data-tooltip-variant="dark"
        data-tooltip-place="left"
        data-tooltip-id={String(order.id) + "cancel"}
        data-tooltip-delay-show={500}
        className="flex items-center gap-4"
      >
        <XCircleIcon
          className="h-5 w-5 text-fuchsia-700 hover:cursor-pointer hover:text-fushia-500"
          onClick={cancelHandler}
          type="button"
        />
      </button>
      <Tooltip id={String(order.id) + "cancel"} />
    </Fragment>
  ) : null;
}
