//#region IMPORTS
import { ShoppingBagIcon } from "@heroicons/react/20/solid";
import { setOrderParams } from "../../features/orders/ordersSlice";
import { useAppDispatch } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Basket } from "../../models/basket";
import { Promo } from "../../models/promo";
import classNames from "../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  latestItem?: boolean;
  isPicker: boolean;
  project?: Promo;
  isAdmin: boolean;
  HQTeam: boolean;
  basket: Basket | null;
}
//#endregion

export default function HomeProjectItem({
  latestItem = false,
  isPicker,
  project,
  isAdmin,
  HQTeam,
  basket,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();

  // Router
  const history = useHistory();
  //#endregion

  //#region LOGIC
  // Fetch data
  const checkoutPromo = () => {
    if (!project) {
      return;
    } else if (isAdmin || isPicker) {
      history.push(`/promos`);
    } else if (HQTeam) {
      dispatch(
        setOrderParams({
          promo_code: project?.code,
          order_status: "Pending",
        })
      );
      history.push(`/approve/${project.code}`);
    } else {
      history.push(`/backorder/promo/${project.id}`);
    }
  };
  //#endregion

  return (
    <div
      onClick={checkoutPromo}
      className={classNames(
        latestItem
          ? "sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2"
          : "sm:aspect-none sm:relative sm:h-full ",
        "group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg cursor-pointer bg-gray-100"
      )}
    >
      {/* Image */}
      {project && (
        <img
          src={project?.picture_url}
          alt={project.code}
          className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
        />
      )}

      {/* Overlay */}
      <div
        aria-hidden="true"
        className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
      />

      {/* Text */}
      <div className="flex items-end p-6 sm:absolute sm:inset-0">
        <div>
          <h3 className="font-semibold text-white">
            <span className="absolute inset-0" />
            {project && project.code}
          </h3>

          {project ? (
            <p aria-hidden="true" className="mt-1 text-base text-white">
              {isAdmin || isPicker ? (
                `${t("rituals.moreCollections")}...`
              ) : HQTeam ? (
                t("rituals.approveOrders")
              ) : basket && basket.promo_id !== project.id ? null : (
                <span className="flex items-center gap-4">
                  <ShoppingBagIcon className="w-6 text-amber-500" />
                  <span
                    aria-hidden="true"
                    className="mt-1 text-base text-amber-500 font-bold text-transform: uppercase"
                  >
                    {t("rituals.reorderFromThisProject")}
                  </span>
                </span>
              )}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}
