//#region IMPORTS
import { motion } from "framer-motion";
//#endregion

//#region INTERFACE
interface Props {
  repeat?: boolean;
  color?: string;
}
//#endregion

export default function RobinLogo({ repeat = false, color = "white" }: Props) {
  //#region SETUP
  const container = {
    hidden: { opacity: repeat ? 1 : 0, scale: repeat ? 1 : 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: repeat ? 0 : 0.15,
        staggerChildren: 0.03,
        type: "spring",
        mass: 1.5,
        stiffness: 150,
      },
    },
  };

  const item = {
    hidden: { y: 10, opacity: 0, scale: 1 },
    visible: {
      y: 0,
      scale: 1,
      opacity: 1,
    },
  };
  //#endregion

  //#region RENDER
  return (
    <motion.div
      className="w-40"
      variants={container}
      initial="hidden"
      animate="visible"
    >
      <svg viewBox="0 0 142.24 181.89">
        <g fill={color}>
          {/* RING */}
          <path
            d="M133.05,76.32c-1.78,1.28-3.7,2.17-5.64,2.53c-3.57,0.66-7.15-0.19-10.38-1.97c7,9.48,11.15,21.19,11.15,33.88
		c0,31.52-25.55,57.06-57.06,57.06c-31.52,0-57.06-25.55-57.06-57.06c0-26.89,18.6-49.42,43.64-55.46
		c-0.86-3.61-0.53-8.33,0.98-14.29c-33.2,5.88-58.42,34.86-58.42,69.75c0,39.14,31.73,70.87,70.87,70.87
		c39.14,0,70.87-31.73,70.87-70.87C141.98,98.25,138.73,86.51,133.05,76.32z"
          />

          <motion.path
            variants={item}
            d="M133.11,39.67c-4.65,1.63-10.92-2.75-13.38-8.15c-2.46-5.4-1.11-11.83,2.3-17.1
		c3.39-5.24,8.84-9.35,12.82-7.77c3.97,1.58,6.49,8.84,6.24,16.3S137.79,38.04,133.11,39.67z"
          />

          <motion.path
            variants={item}
            d="M98.58,0.54c1.91,1.64,2.13,7.51,1.87,12.85c-0.25,5.33-0.96,10.17-3.25,9.73c-2.26-0.43-6.12-6.13-7.68-10.19
		c-1.55-4.06-0.82-6.47,1.36-8.97C93.06,1.48,96.68-1.11,98.58,0.54z"
          />
          <motion.path
            variants={item}
            d="M107.8,22.92c2.47,0.57,3.45,7.75,3.78,14.64c0.35,6.87,0.09,13.47-2.68,14.69c-2.74,1.21-8.02-2.93-10.37-6.8
		c-2.33-3.89-1.76-7.46,0.53-12.28C101.35,28.37,105.34,22.33,107.8,22.92z"
          />

          <motion.path
            variants={item}
            d="M97.97,65.88c-1.98,0.69-6.28-1.9-9.98-4.6c-3.71-2.69-6.86-5.49-5.58-7.03c1.27-1.52,6.98-1.78,10.54-1.07
		c3.56,0.73,4.97,2.4,5.83,5.24C99.65,61.24,99.95,65.2,97.97,65.88z"
          />

          <motion.path
            variants={item}
            d="M88.8,34.12c0.53,4.43-3.33,7.52-8.24,11.66c-4.88,4.13-10.8,9.28-13.04,8c-2.21-1.31-0.77-9.04,1.63-16.06
		c2.43-7.02,5.81-13.28,9.98-13.52C83.31,23.93,88.27,29.69,88.8,34.12z"
          />

          <motion.path
            variants={item}
            d="M127.03,56.44c3.19,3.92,4.36,10.9,1.63,13.12c-2.73,2.22-9.39-0.29-12.58-4.21
		c-3.19-3.92-2.92-9.25-0.19-11.48C118.62,51.66,123.84,52.52,127.03,56.44z"
          />

          {/* R */}
          <path
            d="M101.5,132.62c0,0.97-0.36,1.78-1.06,2.45c-0.71,0.66-1.55,0.99-2.51,0.99h-6c-1.01,0-1.86-0.33-2.55-0.99
		c-0.68-0.66-1.03-1.48-1.03-2.45v-7.34c0-1.17-0.36-2.15-1.07-2.94c-0.72-0.79-1.53-1.18-2.45-1.18H59.83v11.47
		c0,0.97-0.34,1.78-1.03,2.45c-0.69,0.66-1.52,0.99-2.48,0.99h-6.04c-1.02,0-1.87-0.33-2.56-0.99c-0.69-0.66-1.03-1.48-1.03-2.45
		V88.89c0-0.97,0.34-1.78,1.03-2.45c0.69-0.66,1.54-0.99,2.56-0.99h29.82c4.64,0,8.59,1.56,11.85,4.66
		c3.26,3.11,4.89,6.88,4.89,11.32v3.59c0,2.7-0.66,5.23-1.99,7.57c2.34,1.43,4,2.93,4.97,4.51c1.12,1.94,1.68,4.66,1.68,8.18V132.62
		z M83.68,105.23v-3.77c0-1.23-0.36-2.24-1.07-3.04c-0.72-0.8-1.53-1.19-2.45-1.19H59.83v12.16h20.32c0.92,0,1.74-0.4,2.45-1.19
		C83.32,107.39,83.68,106.41,83.68,105.23z"
          />
        </g>
      </svg>
    </motion.div>
  );
  //#endregion
}
