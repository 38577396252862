//#region IMPORTS
import { Fragment, useCallback, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { fetchCurrentUser } from "./features/accounts/accountSlice";
import { useAppDispatch } from "./store/configureStore";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import LoadingScreen from "./components/loading/LoadingScreen";
import PrivateRoute from "./components/routes/PrivateRoute";
import DashboardV2 from "./features/dashBoard/DashboardV2";
import LoginPage from "./features/accounts/LoginPage";
import NotFound from "./components/errors/NotFound";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
//#endregion

export default function App() {
  //#region SETUP
  // Translation
  const { i18n } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();

  // Local state
  const [loading, setLoading] = useState(true);
  const [splash, setSplash] = useState(true);
  //#endregion

  //#region LOGIC
  // Fetch user onload
  const initApp = useCallback(async () => {
    try {
      await dispatch(fetchCurrentUser());
    } catch (error) {
      toast.warning(`${error}`);
    }
  }, [dispatch]);

  useEffect(() => {
    initApp().then(() => setLoading(false));
  }, [initApp]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSplash(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  // Fetch language onload
  useEffect(() => {
    const fetchLanguage = async () => {
      const storedLanguage = localStorage.getItem("robinLanguage");
      if (storedLanguage) {
        await i18n.changeLanguage(storedLanguage);
      }
    };
    fetchLanguage();
  }, [i18n]);
  //#endregion

  //#region FAIL SAFE
  if (loading || splash) return <LoadingScreen />;
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        theme="dark"
        hideProgressBar={true}
      />
      <Switch>
        <Route path="/not-found" component={NotFound} />
        <Route path="/login" component={LoginPage} />
        <PrivateRoute path="/" component={DashboardV2} />
        <Route component={NotFound} />
      </Switch>
    </Fragment>
  );
  //#endregion
}
