//#region IMPORTS
import { TrashIcon } from "@heroicons/react/24/outline";
//#endregion

//#region INTERFACE
interface Props {
  deleteHandler: () => void;
  hide?: boolean;
}
//#endregion

export default function MainTableRowActionDelete({
  deleteHandler,
  hide = false,
}: Props) {
  //#region SETUP
  return !hide ? (
    <div className="flex items-center gap-4">
      <TrashIcon
        className="h-5 w-5 text-red-500 hover:cursor-pointer hover:text-red-400"
        type="button"
        onClick={deleteHandler}
      />
    </div>
  ) : null;
}
