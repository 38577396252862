//#region IMPORTS
import { useHistory } from "react-router-dom";
import Stargazer from "../icons/Stargazer";
//#endregion

export default function NotFound() {
  //#region SETUP
  const history = useHistory();
  //#endregion

  //#region LOGIC
  const handleClick = () => history.push("/");
  //#endregion

  //#region RENDER
  return (
    <main className="h-screen flex flex-col items-center justify-center gap-12">
      <Stargazer width={360} />
      <div className="text-center w-1/2 text-lg">
        <h1 className="font-bold text-8xl text-slate-600 mb-12">woops 404!</h1>
        <h2>
          We looked everywhere, but couldn't find the page you we're looking
          for.
        </h2>
      </div>

      <button
        type="button"
        onClick={handleClick}
        className="rounded-full bg-slate-600 px-8 py-2 text-base font-semibold text-white shadow-xs hover:bg-slate-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 mt-12"
      >
        back
      </button>
    </main>
  );
  //#endregion
}
