//#region IMPORTS
import { useState } from "react";
import { motion } from "framer-motion";
import Identicon from "react-identicons";
//#endregion

//#region INTERFACE
interface Props {
  name: string;
  clickHandler: () => void;
}
//#endregion

export default function Avatar({ name, clickHandler }: Props) {
  //#region SETUP
  const [color, setColor] = useState("red");
  //#endregion

  //#region RENDER
  return (
    <motion.div
      className="flex items-center justify-center relative hover:cursor-pointer"
      onClick={clickHandler}
      whileHover={{
        scale: 1.3,
      }}
      whileTap={{ scale: 0.9 }}
    >
      <div
        className="flex items-center justify-center w-10 h-10 rounded-full border-2 border-solid"
        style={{ borderColor: `#${color}`, backgroundColor: `#${color}25` }}
      >
        <Identicon
          string={name}
          size="24"
          getColor={(value: string) => setColor(value)}
        />
      </div>
    </motion.div>
  );
  //#endregion
}
