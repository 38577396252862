//#region IMPORTS
import { motion, Transition } from "framer-motion";
//#endregion

//#region INTERFACE
interface Props {
  strokeWidth?: string | number;
  transition?: Transition | null;
  lineProps?: any;
  onClick: any;
  isOpen?: boolean;
  style?: any;
  height: any;
  color?: string;
  width: any;
}
//#endregion

export default function DashboardSidebarToggle({
  strokeWidth = 1,
  transition = null,
  lineProps = null,
  isOpen = false,
  height = 24,
  color = "#000",
  width = 24,
  ...props
}: Props) {
  const variant = isOpen ? "opened" : "closed";
  const top = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: 45,
      translateY: 2,
    },
  };
  const bottom = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: -45,
      translateY: -2,
    },
  };
  lineProps = {
    stroke: color,
    strokeWidth: strokeWidth as number,
    vectorEffect: "non-scaling-stroke",
    initial: "closed",
    animate: variant,
    transition,
    ...lineProps,
  };
  const unitHeight = 4;
  const unitWidth = (unitHeight * (width as number)) / (height as number);

  //#region RENDER
  return (
    <div className="hover:cursor-pointer">
      <motion.svg
        viewBox={`0 0 ${unitWidth} ${unitHeight}`}
        overflow="visible"
        preserveAspectRatio="none"
        width={width}
        height={height}
        {...props}
      >
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="0"
          y2="0"
          variants={top}
          {...lineProps}
        />
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="4"
          y2="4"
          variants={bottom}
          {...lineProps}
        />
      </motion.svg>
    </div>
  );
  //#endregion
}
