//#region IMPORTS
import { useLayoutEffect, useState } from "react";
import { motion } from "framer-motion";
import classNames from "../../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  isSubmittingValue?: string;
  isSubmitting?: boolean;
  defaultValue?: string;
  conditionals: boolean[];
}
//#endregion

export default function SubmitButton({
  isSubmittingValue = "Saving...",
  isSubmitting = false,
  defaultValue = "Save",
  conditionals,
}: Props) {
  //#region SETUP
  const [valid, setValid] = useState<boolean>(false);

  const shakeVariants = {
    valid: (i: number) => ({
      rotate: i % 2 === 0 ? [-2, 4, 0] : [2, -4, 0],
      transition: {
        type: "spring",
        delay: -(Math.random() * 0.7 + 0.05),
        repeat: 1,
        duration: Math.random() * 0.07 + 0.23,
      },
    }),
    notValid: {
      rotate: 0,
    },
  };

  //#endregion

  //#region LOGIC
  useLayoutEffect(() => {
    if (conditionals.every((conditional) => conditional)) {
      setValid(true);
    } else setValid(false);
  }, [conditionals]);
  //#endregion

  //#region RENDER
  return (
    <motion.button
      type="submit"
      animate={valid ? "valid" : "notValid"}
      variants={shakeVariants}
      disabled={!valid}
      className={classNames(
        valid
          ? "bg-emerald-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 hover:bg-emerald-500 focus-visible:outline"
          : "bg-gray-600 opacity-40",
        "rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm"
      )}
    >
      {isSubmitting ? isSubmittingValue : defaultValue}
    </motion.button>
  );
  //#endregion
}
