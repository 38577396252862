//#region IMPORTS
import { SquaresPlusIcon as DarkSquaresPlusIcon } from "@heroicons/react/20/solid";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { Icon } from "../../models/icon";
//#endregion

export default function ProductVersionIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#3e5902",
  width = 24,
}: Icon) {
  //#region RENDER
  return selected ? (
    <DarkSquaresPlusIcon
      style={{ width: width + "px", color: selectedColor }}
    />
  ) : (
    <SquaresPlusIcon style={{ width: width + "px", color: color }} />
  );
  //#endregion
}
