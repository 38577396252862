import { XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
  closeHandler: () => void;
  hide?: boolean;
}
export default function DashboardMessage({
  closeHandler,
  hide = false,
}: Props) {
  return !hide ? (
    <div className="fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-amber-500 p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8 z-50">
      <p className="max-w-8xl text-sm/6 text-white font-bold">
        Every month the stores receive their monthly promo packages. This is
        approx. 2 weeks before start of the promotion. In case items are
        missing/damaged, stores can re-order these items via the online order
        portal (ROBIN portal). This online order portal is only open for a few
        days, otherwise we don't have time to ship the re-orders in time before
        the start of the promotion. Ideally the order portal is available:
        Wednesday 1,5 week prior to start promotion and closes on Monday 1 week
        prior to start promotion. Please keep these timings in mind that
        Shopmaterials will not actively inform the local markets on these
        timings, since we aim to communicate these timings in the RET
        newsletter.
      </p>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          onClick={closeHandler}
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
        </button>
      </div>
    </div>
  ) : null;
}
