//#region IMPORTS
import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import classNames from "../../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  changeColorByState?: boolean;
  switchDisabled?: boolean;
  toggleHandler: () => void;
  startValue?: boolean;
  labelRight?: boolean;
  refresh?: boolean;
  label: string;
}
//#endregion

export default function ToggleButton({
  changeColorByState = false,
  switchDisabled = false,
  toggleHandler,
  startValue = false,
  labelRight = false,
  refresh = false,
  label,
}: Props) {
  //#region SETUP
  const [enabled, setEnabled] = useState<boolean>(startValue);
  //#endregion

  useEffect(() => {
    refresh && setEnabled(startValue);
  }, [refresh, startValue]);

  //#region LOGIC
  const toggleClickHandler = () => {
    setEnabled((prev) => !prev);
    toggleHandler();
  };
  //#endregion

  //#region RENDER
  return (
    <Switch.Group
      as="div"
      className={`flex justify-between items-center${
        labelRight && " flex-row-reverse"
      }`}
    >
      <Switch.Label as="span" className="text-sm">
        <span
          className={classNames(
            enabled
              ? changeColorByState
                ? "text-emerald-900"
                : "text-gray-900"
              : changeColorByState
              ? "text-red-900"
              : "text-gray-900",
            "font-medium text-gray-900 text-xs"
          )}
        >
          {label}
        </span>
      </Switch.Label>
      {!switchDisabled && (
        <Switch
          disabled={switchDisabled}
          checked={enabled}
          onChange={toggleClickHandler}
          className={classNames(
            enabled
              ? changeColorByState
                ? "bg-emerald-800"
                : "bg-amber-600"
              : changeColorByState
              ? "bg-red-800"
              : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none mx-2"
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      )}
    </Switch.Group>
  );
  //#endregion
}
