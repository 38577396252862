//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { ORDERSTATUS, ORDERTYPE } from "../../models/order";
import { useTranslation } from "react-i18next";
import { setOrderParams } from "../../features/orders/ordersSlice";
import { useLocation } from "react-router-dom";
import DashboardSidebarListItem from "./DashboardSidebarListItem";
import ProductVersionIcon from "../icons/ProductVersionIcon";
import usePickerManager from "../../hooks/usePickerManager";
import TranslationIcon from "../icons/TranslationIcon";
import CompletedIcon from "../icons/CompletedIcon";
import useHQmanager from "../../hooks/useHQmanager";
import OverViewIcon from "../icons/OverViewIcon";
import ApproveIcon from "../icons/ApproveIcon";
import PendingIcon from "../icons/PendingIcon";
import HistoryIcon from "../icons/HistoryIcon";
import ProductIcon from "../icons/ProductIcon";
import ManageIcon from "../icons/ManageIcon";
import UsersIcon from "../icons/UsersIcon";
import HowToIcon from "../icons/HowToIcon";
import PromoIcon from "../icons/PromoIcon";
import ShopIcon from "../icons/ShopIcon";
//#endregion

//#region INTERFACE
interface Props {
  iconwidth?: number;
  dashOpen: boolean;
}
//#endregion

export default function DashboardSidebarList({
  iconwidth = 32,
  dashOpen,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");

  // Redux
  const dispatch = useAppDispatch();
  const { orders_pending, orders_ordered } = useAppSelector(
    (state) => state.orders
  );

  // Roles
  const { user } = useAppSelector((state) => state.account);
  const isPicker = usePickerManager(user);
  const isHq = useHQmanager(user);
  //#endregion

  //#region RENDER
  return (
    <nav className="flex flex-1 flex-col ml-2">
      <ul className="flex flex-1 flex-col justify-center gap-y-5">
        {/* HOME */}
        <DashboardSidebarListItem
          to="/"
          linkName={t("rituals.home")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === ""}
        >
          <OverViewIcon width={iconwidth} selected={splitLocation[1] === ""} />
        </DashboardSidebarListItem>

        {/* PROMOS */}
        <DashboardSidebarListItem
          to="/promos"
          linkName={t("rituals.projects")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "promos"}
        >
          <PromoIcon
            width={iconwidth}
            selected={splitLocation[1] === "promos"}
          />
        </DashboardSidebarListItem>

        {/* STOREMANAGER */}
        <DashboardSidebarListItem
          to="/stores"
          linkName={t("rituals.stores")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "stores"}
          shop={false}
          picker={true}
          admin={true}
          headquarters={true}
        >
          <ShopIcon
            width={iconwidth}
            selected={splitLocation[1] === "stores"}
          />
        </DashboardSidebarListItem>

        {/* PRODUCTS */}
        <DashboardSidebarListItem
          to="/products"
          linkName={t("rituals.products")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "products"}
          shop={false}
          admin={true}
          picker={false}
          headquarters={false}
        >
          <ProductIcon
            width={iconwidth}
            selected={splitLocation[1] === "products"}
          />
        </DashboardSidebarListItem>

        {/* PRODUCTVERSIONS */}
        <DashboardSidebarListItem
          to="/productversions"
          linkName={t("rituals.versions")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "productversions"}
          shop={false}
          admin={true}
          headquarters={false}
          picker={false}
        >
          <ProductVersionIcon
            width={iconwidth}
            selected={splitLocation[1] === "productversions"}
          />
        </DashboardSidebarListItem>

        {/* APPROVABLE ORDERS */}
        <DashboardSidebarListItem
          to="/approve"
          linkName={t("rituals.approve")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "approve"}
          countItem={isHq ? orders_pending : 0}
          shop={false}
          admin={false}
          picker={false}
          clickhandler={() =>
            dispatch(
              setOrderParams({
                page: 1,
                limit: 10000,
                sort: JSON.stringify({ column: "order_date", order: "desc" }),
                order_status: ORDERSTATUS.pending,
                order_type: ORDERTYPE.backorder,
                include_sub_orders: 1,
              })
            )
          }
        >
          <ApproveIcon
            width={iconwidth}
            selected={splitLocation[1] === "approve"}
          />
        </DashboardSidebarListItem>

        {/* SHIPPABLE ORDERS */}
        <DashboardSidebarListItem
          to="/manage"
          linkName={t("rituals.manage")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "manage"}
          countItem={isPicker ? orders_ordered : 0}
          shop={false}
          headquarters={false}
          admin={false}
          picker={true}
          clickhandler={() =>
            dispatch(
              setOrderParams({
                page: 1,
                limit: 10000,
                sort: JSON.stringify({ column: "order_date", order: "desc" }),
                order_status: ORDERSTATUS.ordered,
                order_type: ORDERTYPE.backorder,
                include_sub_orders: 1,
              })
            )
          }
        >
          <ManageIcon
            width={iconwidth}
            selected={splitLocation[1] === "manage"}
          />
        </DashboardSidebarListItem>

        {/* ALL ORDERS */}
        <DashboardSidebarListItem
          to="/orders"
          linkName={t("rituals.orders")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "orders"}
        >
          <HistoryIcon
            width={iconwidth}
            selected={splitLocation[1] === "orders"}
          />
        </DashboardSidebarListItem>

        {/* PENDING BACKORDERS */}
        <DashboardSidebarListItem
          to="/pending"
          linkName={t("rituals.pending")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "pending"}
          admin={false}
          picker={false}
          headquarters={false}
          clickhandler={() =>
            dispatch(
              setOrderParams({
                page: 1,
                limit: 50,
                sort: JSON.stringify({ column: "order_date", order: "desc" }),
                order_status: ORDERSTATUS.pending,
                order_type: ORDERTYPE.backorder,
                include_sub_orders: 1,
              })
            )
          }
        >
          <PendingIcon
            width={iconwidth}
            selected={splitLocation[1] === "pending"}
          />
        </DashboardSidebarListItem>

        {/* SHIPPED BACKORDERS */}
        <DashboardSidebarListItem
          to="/shipped"
          linkName={t("rituals.shipped")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "shipped"}
          admin={false}
          picker={false}
          clickhandler={() =>
            dispatch(
              setOrderParams({
                page: 1,
                limit: 50,
                sort: JSON.stringify({ column: "order_date", order: "desc" }),
                order_status: ORDERSTATUS.shipped,
                order_type: ORDERTYPE.backorder,
                include_sub_orders: 1,
              })
            )
          }
        >
          <CompletedIcon
            width={iconwidth}
            selected={splitLocation[1] === "shipped"}
          />
        </DashboardSidebarListItem>

        {/* USER MANAGER*/}
        <DashboardSidebarListItem
          to="/users"
          linkName={t("rituals.users")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "users"}
          shop={false}
          admin={true}
          headquarters={false}
          picker={false}
        >
          <UsersIcon
            width={iconwidth}
            selected={splitLocation[1] === "users"}
          />
        </DashboardSidebarListItem>

        {/* FEATURELIST */}
        <DashboardSidebarListItem
          to="/featurelist"
          linkName={t("rituals.howTo")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "featurelist"}
          admin={false}
          picker={false}
        >
          <HowToIcon
            width={iconwidth}
            selected={splitLocation[1] === "featurelist"}
          />
        </DashboardSidebarListItem>

        {/* TRANSLATION */}
        <DashboardSidebarListItem
          to="/translations"
          linkName={t("rituals.translations")}
          dashOpen={dashOpen}
          selected={splitLocation[1] === "translations"}
          headquarters={false}
          picker={false}
          admin={true}
          shop={false}
        >
          <TranslationIcon
            width={iconwidth}
            selected={splitLocation[1] === "translations"}
          />
        </DashboardSidebarListItem>
      </ul>
    </nav>
  );
  //#endregion
}
