//#region IMPORTS
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { UserParams, User, UsersState, UserFilters } from "../../models/user";
import { RootState } from "../../store/configureStore";
import { toast } from "react-toastify";
import getAxiosParams from "../../helpers/getAxiosParams";
import agent from "../../api/agent";
//#endregion

//#region INIT
// Set inital state
const initialExtraState: UsersState = {
  userRoles_loaded: false,
  filters_loaded: false,
  users_loaded: false,
  user_params: initParams(),
  userRoles: [],
  metaData: undefined,
  filters: initFilters(),
  status: "idle",
};

// Set initial query parameters
function initParams(): UserParams {
  return {
    page: 1,
    limit: 25,
  };
}

// Set all filter options
function initFilters(): UserFilters {
  return {
    store_name: [],
    store_country_name: [],
    language: [],
  };
}
//#endregion

//#region CREATE ADAPTER
const usersAdapter = createEntityAdapter<User>();
//#endregion

//#region READ
// Fetch all
export const fetchUsersAsync = createAsyncThunk<
  User[],
  void,
  { state: RootState }
>("users/fetchUsersAsync", async (_, thunkAPI) => {
  const params = getAxiosParams(thunkAPI.getState().users.user_params);
  try {
    const { data, meta } = await agent.Users.list(params);
    thunkAPI.dispatch(setUserMetaData(meta));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Fetch all roles
export const fetchUserRolesAsync = createAsyncThunk(
  "users/fetchUserRolesAsync",
  async (_, thunkAPI) => {
    try {
      return await agent.Users.roles();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

// Fetch all filters
export const fetchFilters = createAsyncThunk(
  "users/fetchFilters",
  async (_, thunkAPI) => {
    try {
      return agent.Users.fetchFilters();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

// Fetch detail
export const fetchUserAsync = createAsyncThunk<
  User,
  number,
  { state: RootState }
>("users/fetchUserAsync", async (id) => {
  try {
    return await agent.Users.detail(id);
  } catch (error) {
    toast.error(`${error}`);
  }
});
//#endregion

//#region DELETE
export const deleteUserAsync = createAsyncThunk<number, { id: number }>(
  "promos/deleteUserAsync",
  async ({ id }, thunkAPI) => {
    try {
      await agent.Users.delete(id);
      return id;
    } catch (error: any) {
      toast.error(error.data);
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
//#endregion

//#region CREATE REDUCER SLICE
export const usersSlice = createSlice({
  name: "users",
  initialState: usersAdapter.getInitialState<UsersState>(initialExtraState),
  reducers: {
    setUserParams: (state, action) => {
      state.users_loaded = false;
      state.user_params = {
        ...state.user_params,
        ...action.payload,
        page: 1,
      };
    },
    clearUserParams: (state) => {
      state.users_loaded = false;
      state.user_params = initParams();
    },
    clearUserParam: (state, action) => {
      const newParmams = { ...state.user_params };
      delete newParmams[action.payload];
      state.users_loaded = false;
      state.user_params = newParmams;
    },
    setUserPageNumber: (state, action) => {
      state.users_loaded = false;
      state.user_params = { ...state.user_params, ...action.payload };
    },
    setUserMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    resetUserParams: (state) => {
      state.users_loaded = false;
      state.user_params = initParams();
    },
    clearUsers: () =>
      usersAdapter.getInitialState<UsersState>(initialExtraState),
    setUser: (state, action) => usersAdapter.upsertOne(state, action.payload),
    removeUser: (state, action) =>
      usersAdapter.removeOne(state, action.payload),
  },
  extraReducers: (builder) => {
    // FETCH users
    builder.addCase(fetchUsersAsync.pending, (state) => {
      state.status = "pendingFetchUsers";
    });
    builder.addCase(fetchUsersAsync.fulfilled, (state, action) => {
      usersAdapter.setAll(state, action.payload);
      state.status = "idle";
      state.users_loaded = true;
    });
    builder.addCase(fetchUsersAsync.rejected, (state, action) => {
      state.status = "idle";
      toast.error(`${action.payload || "Unable to fetch users"}`);
    });

    // FETCH filters
    builder.addCase(fetchFilters.pending, (state) => {
      state.status = "pendingFetchUserFilters";
    });
    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      state.filters.store_name = action.payload.stores.map(
        (store: { name: string }) => store.name
      );
      state.filters.language = action.payload.languages;
      state.filters.store_country_name = action.payload.countries.map(
        (country: { name: string }) => country.name
      );
      state.filters_loaded = true;
      state.status = "idle";
    });
    builder.addCase(fetchFilters.rejected, (state, action) => {
      state.status = "idle";
      toast.error(`${action.payload || "Unable to fetch user filtering"}`);
    });

    // FETCH roles
    builder.addCase(fetchUserRolesAsync.pending, (state) => {
      state.status = "pendingFetchUserRoles";
    });
    builder.addCase(fetchUserRolesAsync.fulfilled, (state, action) => {
      state.userRoles = action.payload;
      state.userRoles_loaded = true;
      state.status = "idle";
    });
    builder.addCase(fetchUserRolesAsync.rejected, (state) => {
      state.status = "idle";
    });

    // FETCH user by id
    builder.addCase(fetchUserAsync.pending, (state) => {
      state.status = "pendingFetchUser";
    });
    builder.addCase(fetchUserAsync.fulfilled, (state, action) => {
      usersAdapter.upsertOne(state, action.payload);
      state.status = "idle";
    });
    builder.addCase(fetchUserAsync.rejected, (state) => {
      state.status = "idle";
    });

    // DELETE user
    builder.addCase(deleteUserAsync.pending, (state) => {
      state.status = "PendingDeleteUser";
    });
    builder.addCase(deleteUserAsync.fulfilled, (state, action) => {
      usersAdapter.removeOne(state, action.payload);
      toast.success(`User with id ${action.payload} deleted`);
      state.status = "idle";
    });
    builder.addCase(deleteUserAsync.rejected, (state) => {
      state.status = "idle";
      toast.error("Something went wrong");
    });
  },
});
//#endregion

//#region NORMALISED STATE
export const usersSelectors = usersAdapter.getSelectors(
  (state: RootState) => state.users
);
//#endregion

//#region EXPORTS
export const {
  setUserPageNumber,
  setUserMetaData,
  clearUserParams,
  resetUserParams,
  clearUserParam,
  setUserParams,
  clearUsers,
  removeUser,
  setUser,
} = usersSlice.actions;
//#endregion
