//#region IMPORTS
import {
  fetchFilters,
  deleteProductVersionAsync,
} from "../../features/productVersions/productVersionsSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useState, useEffect, Fragment } from "react";
import { DELETE_PRODUCT_VERSION_SURE } from "../../models/_consts";
import { ProductVersion } from "../../models/productVersion";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { openModal } from "../../features/dashBoard/dashboardSlice";
import { motion } from "framer-motion";
import ProductVersionsCreateForm from "../../components/productVersions/productVersionsForm/ProductVersionsCreateForm";
import ProductVersionsFilterBar from "../../components/productVersions/ProductVersionsFilterBar";
import ProductVersionsTable from "../../components/productVersions/ProductVersionsTable";
import ImageUploaderV3 from "../../components/imageUpload/ImageUploaderV3";
import useAdminManager from "../../hooks/useAdminManager";
import MainContainer from "../../components/_layout/MainContainer";
import CancelIcon from "../../components/icons/CancelIcon";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import AreYouSure from "../modalTypes/AreYouSure";
import MainModal from "../_layout/MainModal";
//#endregion

export default function ProductVersionsContainer() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);
  const { product_versions_loaded, filters_loaded, metaData } = useAppSelector(
    (state) => state.productVersions
  );

  // Roles
  const isAdmin = useAdminManager(user);

  // Local State
  const [create, setCreate] = useState<boolean>(false);
  const [productVersionToManage, setProductVersionToManage] =
    useState<ProductVersion>();

  //#endregion

  //#region LOGIC
  // Load filters
  useEffect(() => {
    const fetchFiltering = async () => {
      await dispatch(fetchFilters());
    };
    !filters_loaded && fetchFiltering();
  }, [filters_loaded, dispatch]);

  // Create new productversion
  const toggleCreate = () => {
    setCreate((prev) => !prev);
  };

  // Redirect to detail page
  const detailHandler = (id: number) => {
    history.push(`productversions/${id}`, true);
  };

  // Set delete warning
  const deleteWarningHandler = async (productVersion: ProductVersion) => {
    setProductVersionToManage(productVersion);
    dispatch(openModal(DELETE_PRODUCT_VERSION_SURE));
  };

  // Confirm delete
  const deleteHandler = async () => {
    productVersionToManage &&
      (await dispatch(
        deleteProductVersionAsync({ id: productVersionToManage.id })
      ));
    cancelCUD();
  };

  // Cancel CUD product
  const cancelCUD = () => {
    dispatch(openModal(false));
    setProductVersionToManage(undefined);
  };
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* MODALS */}
      <MainModal type={DELETE_PRODUCT_VERSION_SURE} closeHandler={cancelCUD}>
        <AreYouSure
          confirmHandler={deleteHandler}
          closeHandler={cancelCUD}
          message={`Are you sure you want to delete product version ${productVersionToManage?.name}`}
          title="Delete product version?"
        />
      </MainModal>

      {/* BODY */}
      <MainContainer>
        <PageHeader
          createClickHandler={toggleCreate}
          createButtonValue={create ? t("rituals.save") : t("rituals.add")}
          isAdmin={isAdmin}
          create={create}
          title={t("rituals.versions")}
          extraButtons={
            <div>
              <ImageUploaderV3 />
            </div>
          }
        />

        <ProductVersionsFilterBar />

        {/* Create from form */}
        {isAdmin &&
          (create ? (
            <div className="productversions-page__content__new-box">
              <motion.button
                whileHover={{ scale: 1.1, rotate: "90deg" }}
                className="productversions-page__content__new-box__cancel"
                onClick={toggleCreate}
              >
                <CancelIcon />
              </motion.button>
              <ProductVersionsCreateForm
                cancelCUD={toggleCreate}
                directTo={() => void {}}
              />
            </div>
          ) : (
            <ProductVersionsTable
              deleteHandler={(productVersion: ProductVersion) =>
                deleteWarningHandler(productVersion)
              }
              detailHandler={(productVersion: ProductVersion) =>
                detailHandler(productVersion.id)
              }
              itemsLoaded={product_versions_loaded}
              metaData={metaData}
              hide={!product_versions_loaded && !filters_loaded}
            />
          ))}
      </MainContainer>
    </Fragment>
  );
  //#endregion
}
