//#region IMPORTS
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon as DarkXCircleIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import classNames from "../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  filterParameterName: string;
  displayExplanation?: string;
  clearFilterAction: () => void;
  selectedValue?: string;
  filterAction: (fromDate: string, toDate: string) => void;
  displayText?: string;
  disabled?: boolean;
  selected?: boolean;
  hide?: boolean;
}
//#endregion

export default function MainFilterBarBetweenDatesItem({
  filterParameterName,
  clearFilterAction,
  displayExplanation = "This is a filter.",
  selectedValue,
  filterAction,
  displayText = "filteritem",
  selected = true,
  disabled = false,
  hide = false,
}: Props) {
  //#region SETUP
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  //#endregion

  //#region LOGIC
  useEffect(() => {
    if (selectedValue) {
      setFromDate(JSON.parse(selectedValue).from);
      setToDate(JSON.parse(selectedValue).to);
    }
  }, [selectedValue]);
  //#endregion

  //#region RENDER
  return !hide ? (
    <div
      className={classNames(
        disabled ? "opacity-30" : "opacity-100",
        !selected ? "" : "hover:bg-gray-100",
        "group relative -mx-3 flex flex-col justify-between rounded-lg p-3 leading-6 "
      )}
    >
      <div className="w-full flex gap-4">
        <button
          className="flex items-start pt-0.5"
          data-tooltip-id={filterParameterName}
          data-tooltip-content="Clear filter"
          disabled={!selected || disabled}
          onClick={() => {
            clearFilterAction();
            setFromDate("");
            setToDate("");
          }}
        >
          {selected ? (
            <DarkXCircleIcon className="h5 w-5 text-red-500" />
          ) : (
            <XCircleIcon className="h5 w-5 text-gray-200" />
          )}
          {selected && <Tooltip id={filterParameterName} />}
        </button>

        <div className={classNames(selected ? "opacity-30" : "opacity-100")}>
          <p className="text-slate-700 text-base font-medium">{displayText}</p>
          <p className="mt-1 text-gray-400 text-xs">{displayExplanation}</p>
        </div>
      </div>

      <form
        className="mt-4 w-full flex gap-4"
        onSubmit={(e: any) => {
          e.preventDefault();
          filterAction(fromDate, toDate);
        }}
      >
        <button
          className="h-full w-5 text-gray-400"
          type="submit"
          disabled={!fromDate || !toDate || disabled}
        >
          <MagnifyingGlassIcon aria-hidden="true" />
        </button>
        <div className="space-y-1 w-full">
          <div className="flex gap-2">
            <label htmlFor={filterParameterName + "from"} className="w-16"> from: </label>
            <input
              id={filterParameterName + "from"}
              name="From"
              disabled={disabled}
              value={fromDate}
              className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              type="date"
              onChange={(e: any) => setFromDate(e.target.value)}
            />
          </div>
          <div className="flex gap-2">
            <label htmlFor={filterParameterName + "from"} className="w-16"> to: </label>
            <input
              id={filterParameterName + "to"}
              name="From"
              disabled={disabled}
              value={toDate}
              className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              type="date"
              onChange={(e: any) => setToDate(e.target.value)}
            />
          </div>
        </div>
      </form>
    </div>
  ) : null;
  //#endregion
}
