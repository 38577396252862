//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { setStoresPageNumber } from "../../features/stores/storesSlice";
import { MetaData } from "../../models/pagination";
import { Store } from "../../models/store";
import StoresTableHeader from "./StoresTableHeader";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import StoresTableRow from "./StoresTableRow";
import useHQmanager from "../../hooks/useHQmanager";
import MainTable from "../_layout/MainTable";
//#endregion

//#region INTERFACE
interface Props {
  itemsLoaded: boolean;
  metaData?: MetaData;
  items: Store[];
  hide?: boolean;
}
//#endregion

export default function StoresTable({
  itemsLoaded,
  metaData,
  items,
  hide = false,
}: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);

  // Roles
  const isPicker = usePickerManager(user);
  const isHQTeam = useHQmanager(user);
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region RENDER
  return (
    <MainTable
      itemsLoaded={itemsLoaded}
      metaData={metaData}
      onPageChangeHandler={(page: number) =>
        dispatch(setStoresPageNumber({ page: page }))
      }
      header={<StoresTableHeader />}
      hide={hide}
    >
      {items.map((store) => (
        <StoresTableRow
          isHQTeam={isHQTeam}
          isPicker={isPicker}
          isAdmin={isAdmin}
          store={store}
          key={store.id}
        />
      ))}
    </MainTable>
  );
  //#endregion
}
