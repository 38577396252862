//#region IMPORTS
import { useAppDispatch } from "../../store/configureStore";
import { setManageMode } from "../../features/stores/storesSlice";
import { useHistory } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/24/outline";
import { Store } from "../../models/store";
//#endregion

//#region INTERFACE
interface Props {
  isPicker: boolean;
  isHQTeam: boolean;
  isAdmin: boolean;
  store: Store;
}
//#endregion

export default function StoresTableRowActionReadUpdate({
  isPicker,
  isHQTeam,
  isAdmin,
  store,
}: Props) {
  //#region SETUP
  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  //#endregion

  //#region LOGIC
  // Redirect to detail page
  const detailHandler = () => {
    history.push(`/stores/manage/${store.id}`, true);
    dispatch(setManageMode(false));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="flex gap-1.5 items-center h-full mr-4">
      {isAdmin || isPicker || isHQTeam ? (
        <EyeIcon
          className="w-5 h-5 text-emerald-500 hover:text-emerald-400 hover:cursor-pointer"
          onClick={detailHandler}
          type="button"
        />
      ) : null}
    </div>
  );
  //#endregion
}
