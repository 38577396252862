//#region IMPORTS
import {
  setStoreParams,
  clearStoreParam,
  clearStoreParams,
} from "../../features/stores/storesSlice";
import {
  storeFormatSelectors,
  fetchStoreFormatsAsync,
} from "../../features/storeFormats/storeFormatSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useEffect } from "react";
import { format } from "date-fns";
import MainFilterBarBetweenDatesItem from "../_layout/MainFilterBarBetweenDatesItem";
import MainFilterBarToggleActiveItem from "../_layout/MainFilterBarToggleActiveItem";
import MainFilterBarItem from "../_layout/MainFilterBarItem";
import MainFilterBar from "../_layout/MainFilterBar";
//#endregion

export default function StoresFilterBar() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const {
    page,
    sort,
    limit,
    active,
    opening_date,
    store_format_id,
    ...params
  } = useAppSelector((state) => state.stores.store_params);
  const { filters, filters_loaded } = useAppSelector((state) => state.stores);
  const { storeFormats_loaded } = useAppSelector((state) => state.storeFormats);
  const storeFormats = useAppSelector(storeFormatSelectors.selectAll);
  //#endregion

  //#region LOGIC
  // Load storeformats
  useEffect(() => {
    !storeFormats_loaded && dispatch(fetchStoreFormatsAsync());
  }, [storeFormats_loaded, dispatch]);
  //#endregion

  //#region RENDER
  return (
    <MainFilterBar
      paramNamesList={params}
      clearAllFiltersHandler={() => dispatch(clearStoreParams())}
      clearFilterHandler={(paramName: string) => {
        dispatch(clearStoreParam(paramName));
        // UX only values cleanup
        paramName === "store_format" &&
          dispatch(clearStoreParam("store_format_id"));
        paramName === "between_opening_date" &&
          dispatch(clearStoreParam("opening_date"));
        paramName === "active_store" && dispatch(clearStoreParam("active"));
      }}
      disabled={!filters_loaded}
    >
      {/* external_store_id */}
      <MainFilterBarItem
        filterParameterName="external_store_id"
        displayExplanation="Search the stores by id"
        clearFilterAction={() => dispatch(clearStoreParam("external_store_id"))}
        selectedValue={params.external_store_id}
        filterAction={(value: string) =>
          dispatch(setStoreParams({ external_store_id: value }))
        }
        displayText="Store id"
        selected={!!params.external_store_id}
      />

      {/* store_name */}
      <MainFilterBarItem
        filterParameterName="store_name"
        displayExplanation="Search the stores by name"
        clearFilterAction={() => dispatch(clearStoreParam("store_name"))}
        selectedValue={params.store_name}
        filterAction={(value: string) =>
          dispatch(setStoreParams({ store_name: value }))
        }
        displayText="Store name"
        selected={!!params.store_name}
      />

      {/* address_1 */}
      <MainFilterBarItem
        filterParameterName="address_1"
        displayExplanation="Search the stores by streetname"
        clearFilterAction={() => dispatch(clearStoreParam("address_1"))}
        selectedValue={params.address_1}
        filterAction={(value: string) =>
          dispatch(setStoreParams({ address_1: value }))
        }
        displayText="Street name"
        selected={!!params.address_1}
      />

      {/* postal_code */}
      <MainFilterBarItem
        filterParameterName="postal_code"
        displayExplanation="Search the stores by streetname"
        clearFilterAction={() => dispatch(clearStoreParam("postal_code"))}
        selectedValue={params.postal_code}
        filterAction={(value: string) =>
          dispatch(setStoreParams({ postal_code: value }))
        }
        displayText="Postal code"
        selected={!!params.postal_code}
      />

      {/* city */}
      <MainFilterBarItem
        filterParameterName="city"
        displayExplanation="Filter the stores by city"
        clearFilterAction={() => dispatch(clearStoreParam("city"))}
        selectBoxItems={filters.city.filter(
          (item) => item !== null && item !== "-"
        )}
        selectedValue={params.city}
        filterAction={(value: string) => {
          dispatch(setStoreParams({ city: value }));
        }}
        displayText="City"
        selectBox
        selected={!!params.city}
      />

      {/* country_name */}
      <MainFilterBarItem
        filterParameterName="country_name"
        displayExplanation="Filter the stores by country"
        clearFilterAction={() => dispatch(clearStoreParam("country_name"))}
        selectBoxItems={filters.country_name.filter(
          (item) => item !== null && item !== "-"
        )}
        selectedValue={params.country_name}
        filterAction={(value: string) => {
          dispatch(setStoreParams({ country_name: value }));
        }}
        displayText="Country"
        selectBox
        selected={!!params.country_name}
      />

      {/* language */}
      <MainFilterBarItem
        filterParameterName="language"
        displayExplanation="Filter the stores by language"
        clearFilterAction={() => dispatch(clearStoreParam("language"))}
        selectBoxItems={filters.language.filter(
          (item) => item !== null && item !== "-"
        )}
        selectedValue={params.language}
        filterAction={(value: string) => {
          dispatch(setStoreParams({ language: value }));
        }}
        displayText="Language"
        selectBox
        selected={!!params.language}
      />

      {/* store_format_id */}
      <MainFilterBarItem
        filterParameterName="store_format_id"
        displayExplanation="Filter the stores by store format"
        clearFilterAction={() => {
          dispatch(clearStoreParam("store_format_id"));
          dispatch(clearStoreParam("store_format"));
        }}
        selectBoxItems={storeFormats
          .sort((a, b) => {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();
            if (x > y) {
              return 1;
            }
            if (x < y) {
              return -1;
            }
            return 0;
          })
          .map((sf) => sf.name)}
        selectedValue={params?.store_format}
        filterAction={(value: string) => {
          dispatch(
            setStoreParams({
              store_format_id: storeFormats.find((f) => f.name === value)?.id,
              store_format: value,
            })
          );
        }}
        displayText="Store format"
        selectBox
        selected={!!params.store_format}
        disabled={!storeFormats_loaded}
      />

      {/* comment */}
      <MainFilterBarItem
        filterParameterName="comment"
        displayExplanation="Search the stores by comment text"
        clearFilterAction={() => dispatch(clearStoreParam("comment"))}
        selectedValue={params.comment}
        filterAction={(value: string) =>
          dispatch(setStoreParams({ comment: value }))
        }
        displayText="Comment"
        selected={!!params.comment}
      />

      {/* opening_date */}
      <MainFilterBarBetweenDatesItem
        filterParameterName="opening_date"
        displayExplanation="Search opening dates between two dates"
        clearFilterAction={() => {
          dispatch(clearStoreParam("between_opening_date"));
          dispatch(clearStoreParam("opening_date"));
        }}
        selectedValue={params.opening_date}
        filterAction={(fromDate: string, toDate: string) => {
          const searchObject = {
            from: fromDate,
            to: toDate,
          };
          dispatch(
            setStoreParams({
              opening_date: JSON.stringify(searchObject),
              between_opening_date: `opening between ${format(
                new Date(fromDate),
                "dd-MM-yyyy"
              )} and ${format(new Date(toDate), "dd-MM-yyyy")}`,
            })
          );
        }}
        displayText="Opening date"
        selected={!!params.between_opening_date}
      />

      {/* active */}
      <MainFilterBarToggleActiveItem
        clearFilterAction={() => {
          dispatch(clearStoreParam("active_store"));
          dispatch(clearStoreParam("active"));
        }}
        filterAction={(value?: string) =>
          dispatch(
            setStoreParams({
              active: value,
              active_store:
                value === "Yes"
                  ? "Active"
                  : value === "No"
                  ? "Disabled"
                  : undefined,
            })
          )
        }
        selectedValue={params.active}
      />
    </MainFilterBar>
  );
  //#endregion
}
