//#region IMPORTS
import {
  setTranslationManagerParams,
  clearTranslationManagerParam,
  clearTranslationManagerParams,
} from "../../features/translationManager/translatioManagerSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import MainFilterBarItem from "../_layout/MainFilterBarItem";
import MainFilterBar from "../_layout/MainFilterBar";
//#endregion

export default function TranslationManagerFilterBar() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { page, sort, limit, ...params } = useAppSelector(
    (state) => state.translationManagement.translationParams
  );
  const { translationsLoaded } = useAppSelector(
    (state) => state.translationManagement
  );
  //#endregion

  //#region RENDER
  return (
    <MainFilterBar
      paramNamesList={params}
      clearAllFiltersHandler={() => dispatch(clearTranslationManagerParams())}
      clearFilterHandler={(paramName: string) => {
        dispatch(clearTranslationManagerParam(paramName));
      }}
      disabled={!translationsLoaded}
    >
      {/* key */}
      <MainFilterBarItem
        filterParameterName="key"
        displayExplanation="Search translation by keyword"
        clearFilterAction={() => dispatch(clearTranslationManagerParam("key"))}
        selectedValue={params.key}
        filterAction={(value: string) =>
          dispatch(setTranslationManagerParams({ key: value }))
        }
        displayText="Keyword"
        selected={!!params.key}
      />
    </MainFilterBar>
  );
  //#endregion
}
