//#region IMPORTS
import RobinLogo from "../logos/RobinLogo";
//#endregion

//#region INTERFACE
interface Props {
  showLogo?: boolean;
}
//#endregion

export default function LoadingScreen({ showLogo = true }: Props) {
  //#region RENDER
  return (
    <section className="fixed flex items-center justify-center top-0 h-full w-full bg-amber-500 text-4xl text-center">
      {showLogo && <RobinLogo />}
    </section>
  );
  //#endregion
}
