//#region IMPORTS
import {
  STORE_ADDRESS_MODAL,
  STORE_ADDRESS_MODAL_SAFETY,
} from "../../models/_consts";
import { StoreDeliveryAddress } from "../../models/store";
import { useAppDispatch } from "../../store/configureStore";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { openModal } from "../../features/dashBoard/dashboardSlice";
import { Tooltip } from "react-tooltip";
import StoreManageFormAddressListItem from "./StoreManageFormAddressListItem";
import classNames from "../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  selectAddressHandler: (values: StoreDeliveryAddress | null) => void;
  same_as_address?: boolean;
  updateMode?: boolean;
  manageMode?: boolean;
  addresses: StoreDeliveryAddress[];
}
//#endregion

export default function StoreManageFormAddressList({
  selectAddressHandler,
  same_as_address = false,
  updateMode = false,
  manageMode = false,
  addresses,
}: Props) {
  //#region SETUP
  const dispatch = useAppDispatch();
  //#endregion

  //#region LOGIC
  const addHandler = () => {
    dispatch(openModal(STORE_ADDRESS_MODAL));
    selectAddressHandler(null);
  };

  const editHandler = (address: StoreDeliveryAddress) => {
    dispatch(openModal(STORE_ADDRESS_MODAL));
    selectAddressHandler && selectAddressHandler(address);
  };

  const deleteHandler = (address: StoreDeliveryAddress) => {
    dispatch(openModal(STORE_ADDRESS_MODAL_SAFETY));
    selectAddressHandler && selectAddressHandler(address);
  };
  //#endregion

  console.log(same_as_address);
  //#region RENDER
  return (
    <ul
      className={classNames(
        addresses.length > 0
          ? "divide-gray-100 border-gray-200 border text-slate-800"
          : "divide-amber-100 border-amber-200 border border-dashed text-amber-500",
        "divide-y rounded-md"
      )}
    >
      {addresses.map((address, index) => (
        <StoreManageFormAddressListItem
          key={index}
          deleteHandler={() => deleteHandler(address)}
          editHandler={() => editHandler(address)}
          manageMode={manageMode}
          address={address}
        />
      ))}
      {manageMode ? (
        same_as_address && updateMode ? null : same_as_address &&
          !updateMode &&
          addresses.length > 0 ? null : (
          <li className="flex items-center gap-4 py-4 pl-4 pr-5 text-sm text-amber-500 leading-6">
            <PlusCircleIcon
              role="button"
              data-tooltip-id="addAtLeastOne"
              data-tooltip-content="Add address"
              className="w-8 h-8"
              onClick={addHandler}
            />
            <span className="text-amber-500">
              {!same_as_address && addresses.length > 0
                ? "Add address"
                : "Please add at least one address..."}
            </span>
            <Tooltip id="addAtLeastOne" />
          </li>
        )
      ) : null}
    </ul>
  );
  //#endregion
}
