//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { fetchStoresAsync } from "./storesSlice";
import { useEffect } from "react";
import StoresContainer from "../../components/stores/StoresContainer";
//#endregion

export default function StoresPage() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { stores_loaded } = useAppSelector((state) => state.stores);
  //#endregion

  //#region LOGIC
  // Load stores
  useEffect(() => {
    const fetchOnload = async () => {
      if (!stores_loaded) {
        await dispatch(fetchStoresAsync());
      }
    };
    fetchOnload();
  }, [stores_loaded, dispatch]);
  //#endregion

  //#region RENDER
  return <StoresContainer />;
  //#endregion
}
