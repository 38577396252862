//#region IMPORTS
import MainTableRowActionDelete from "../_layout/MainTableRowActionDelete";
import MainTableRowActionRead from "../_layout/MainTableRowActionRead";
import { User } from "../../models/user";
//#endregion

//#region INTERFACE
interface Props {
  deleteHandler: (user: User) => void;
  detailHandler: (user: User) => void;
  isAdmin: boolean;
  user: User;
}
//#endregion

export default function StoresTableRow({
  deleteHandler,
  detailHandler,
  isAdmin = false,
  user,
}: Props) {
  //#region RENDER
  return (
    <tr className="border-t border-slate-300 h-10">
      {/* Actions */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-500 sm:pl-0">
        <MainTableRowActionRead
          detailHandler={() => detailHandler(user)}
          tooltipId={String(user.email)}
        />
      </td>
      {/* username */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-500 sm:pl-0">
        {user.username}
      </td>

      {/* email */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-500 sm:pl-0">
        {user.email}
      </td>

      {/* function */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
        {user.function}
      </td>

      {/* external_store_id */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
        {user.store ? user.store.external_store_id : "none"}
      </td>

      {/* Delete */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
        <MainTableRowActionDelete
          deleteHandler={() => deleteHandler(user)}
          hide={!isAdmin}
        />
      </td>
    </tr>
  );
  //#endregion
}
