//#region IMPORTS
import RobinLogo from "../logos/RobinLogo";
//#endregion

//#region INTERFACE
interface Props {
  color?: string;
}
//#endregion

export default function LoadingSpinner({ color }: Props) {
  //#region RENDER
  return (
    <section className="bg-white py-96 flex items-center justify-center">
      <RobinLogo repeat={true} color={color ?? "#fbb033"} />
    </section>
  );
  //#endregion
}
