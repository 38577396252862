//#region IMPORTS
import {
  productsSelectors,
  setProductPageNumber,
} from "../../features/products/productsSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { MetaData } from "../../models/pagination";
import { Product } from "../../models/product";
import ProductsTableHeader from "./ProductsTableHeader";
import ProductsTableRow from "./ProductsTableRow";
import useAdminManager from "../../hooks/useAdminManager";
import MainTable from "../_layout/MainTable";
//#endregion

//#region INTERFACE
interface Props {
  deleteHandler: (product: Product) => void;
  detailHandler: (product: Product) => void;
  itemsLoaded: boolean;
  metaData?: MetaData;
  hide?: boolean;
}
//#endregion

export default function ProductsTable({
  deleteHandler,
  detailHandler,
  itemsLoaded,
  metaData,
  hide = false,
}: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);
  const items = useAppSelector(productsSelectors.selectAll);

  // Roles
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region RENDER
  return (
    <MainTable
      itemsLoaded={itemsLoaded}
      metaData={metaData}
      onPageChangeHandler={(page: number) =>
        dispatch(setProductPageNumber({ page: page }))
      }
      header={<ProductsTableHeader />}
      hide={hide}
    >
      {items.map((product) => (
        <ProductsTableRow
          deleteHandler={(product: Product) => deleteHandler(product)}
          detailHandler={(product: Product) => detailHandler(product)}
          isAdmin={isAdmin}
          item={product}
          key={product.id}
        />
      ))}
    </MainTable>
  );
  //#endregion
}
