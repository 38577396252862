//#region IMPORTS
import { Fragment } from "react";
import { EyeIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
//#endregion

//#region INTERFACE
interface Props {
  detailHandler: () => void;
  tooltipId?: string;
}
//#endregion

export default function MainTableRowActionRead({
  detailHandler,
  tooltipId,
}: Props) {
  //#region RENDER
  return (
    <Fragment>
      <button
        data-tooltip-content="Show detail"
        data-tooltip-variant="dark"
        data-tooltip-place="right"
        data-tooltip-id={tooltipId + "read"}
        data-tooltip-delay-show={500}
        className="flex items-center gap-4"
      >
        <EyeIcon
          className="w-5 h-5 text-emerald-500 hover:text-emerald-400"
          onClick={detailHandler}
          type="button"
        />
      </button>
      {tooltipId && <Tooltip id={tooltipId + "read"} />}
    </Fragment>
  );
  //#endregion
}
