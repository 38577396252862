//#region IMPORTS
import { FieldValues, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Promo } from "../../models/promo";
import { toast } from "react-toastify";
import getAxiosParams from "../../helpers/getAxiosParams";
import classNames from "../../helpers/classNames";
import agent from "../../api/agent";
//#endregion

export default function HomeInfoItemsExcelDownload() {
  //#region SETUP
  // Redux
  const [promos, setPromos] = useState<Promo[]>([]);
  const [promosForExcelDownloadReady, setPromosForExcelDownloadReady] =
    useState<boolean>(false);

  // Local state
  const [value, setValue] = useState<string>();

  // Form setup
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({ mode: "all" });
  //#endregion

  //#region LOGIC
  // Fetch promos to select
  useEffect(() => {
    const fetchPromos = async () => {
      const promoParams = getAxiosParams({ page: 1, limit: 100 });
      const response = await agent.Promos.list(promoParams);
      setPromos(response.data);
      setPromosForExcelDownloadReady(true);
    };
    fetchPromos();
  }, []);

  async function submitForm(data: FieldValues) {
    if (data.promo_code !== "false") {
      try {
        const params = new URLSearchParams({
          promo_code: data.promo_code,
          order_type: "backorder",
        });
        const response = await agent.Orders.downloadspreadsheat(params);
        const url = URL.createObjectURL(new Blob([response]));
        const link = Object.assign(document.createElement("a"), {
          href: url,
          download: `${data.promo_code}.xlsx`,
        });
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        toast.error(`${error}`);
      }
    }
  }
  //#endregion

  //#region RENDER
  return (
    <div className="h-full flex flex-col justify-end">
      <h4 className="text-xl mb-1">
        {promosForExcelDownloadReady ? "excel export" : "loading resources..."}
      </h4>
      <form
        onSubmit={handleSubmit(submitForm)}
        className={classNames(
          promosForExcelDownloadReady ? "opacity-100" : "opacity-50",
          "flex flex-col"
        )}
      >
        <select
          disabled={!promosForExcelDownloadReady}
          className="mt-2 block w-full rounded-md border-0 py-1.5 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-0 sm:text-sm sm:leading-6"
          {...register("promo_code", {
            required: "promocode is required",
            onChange: (e: any) => setValue(e.target.value),
          })}
          id="promocode"
        >
          <option key={0} disabled={isValid} className="opacity-30">
            Choose a project
          </option>
          {promos.map((promo) => (
            <option key={promo.id} value={promo.code}>
              {promo.code}
            </option>
          ))}
        </select>
        <button
          disabled={!isValid || !promosForExcelDownloadReady || !value}
          type="submit"
          className={classNames(
            isValid && value
              ? "bg-slate-800 hover:bg-slate-600"
              : "bg-slate-600",
            "rounded-md border-0 py-1.5 px-3 py-1.5 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-800 mt-4"
          )}
        >
          {isSubmitting ? "downloading..." : "download"}
        </button>
      </form>
    </div>
  );
  //#endregion
}
