//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { fetchProductVersionsAsync } from "./productVersionsSlice";
import { useEffect } from "react";
import ProductVersionsContainer from "../../components/productVersions/ProductVersionsContainer";
//#endregion

export default function ProductVersionsPage() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { product_versions_loaded } = useAppSelector(
    (state) => state.productVersions
  );
  //#endregion

  //#region LOGIC
  // Load  products
  useEffect(() => {
    const fetchData = async () => {
      if (!product_versions_loaded) {
        await dispatch(fetchProductVersionsAsync());
      }
    };
    fetchData();
  }, [product_versions_loaded, dispatch]);
  //#endregion

  //#region RENDER
  return <ProductVersionsContainer />;
  //#endregion
}
