//#region IMPORTS
import { useAppSelector } from "../../store/configureStore";
import { AppUser } from "../../models/user";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import BasketIconVtwo from "../icons/BasketIconVtwo";
import useHQmanager from "../../hooks/useHQmanager";
import Avatar from "../avatar/Avatar";
//#endregion

//#region INTERFACE
interface Props {
  avatarClickHandler: () => void;
  user: AppUser | null;
}
//#endregion

export default function DashboardHeaderBarAccount({
  avatarClickHandler,
  user,
}: Props) {
  //#region SETUP
  // Redux
  const { basket } = useAppSelector((state) => state.basket);

  // Roles
  const isPicker = usePickerManager(user);
  const isAdmin = useAdminManager(user);
  const hqTeam = useHQmanager(user);

  // Count unique items in basket
  const itemCount = basket?.items.length;
  //#endregion

  //#region RENDER
  return (
    <div className="flex items-center gap-x-4 lg:gap-x-6">
      {/* Basket */}
      {hqTeam || isAdmin || isPicker ? null : (
        <NavLink to="/basket">
          <div className="relative">
            <BasketIconVtwo className="w-8" />
            <motion.span
              className="text-sm absolute top-0 left-5 inline-flex items-center justify-center w-4 h-4 bg-red-500 text-white font-bold rounded-full my-1 border-1 border-white"
              animate={{ scale: 1.3 }}
              transition={{ type: "spring", stiffness: 80 }}
              key={itemCount}
              style={{ display: basket ? "inline-flex" : "none" }}
            >
              {itemCount}
            </motion.span>
          </div>
        </NavLink>
      )}

      {/* Separator */}
      <div
        className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
        aria-hidden="true"
      />

      {/* Profile */}
      <div className="relative">
        <div className="-m-1.5 flex items-center p-1.5 gap-4">
          <span className="hidden lg:flex lg:items-center">
            <span
              className="ml-4 text-2xl serif leading-6 text-gray-900"
              aria-hidden="true"
            >
              {user?.name}
            </span>
          </span>

          {user && (
            <div className="hover:cursor-pointer">
              <Avatar name={user.name} clickHandler={avatarClickHandler} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
  //#endregion
}
