//#region IMPORTS
import {
  ArrowUpCircleIcon,
  ArrowDownCircleIcon,
} from "@heroicons/react/24/outline";
import { SortingState, SortingStateOption } from "../../models/sorting";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import classNames from "../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  sortChangedHandler?: (option: SortingStateOption) => void;
  headerText: string;
  className: string;
  sortName?: string;
  disabled?: boolean;
  params?: any;
  hide?: boolean;
}
//#endregion

export default function MainTableHeaderCell({
  sortChangedHandler,
  headerText,
  className,
  sortName,
  disabled = false,
  params,
  hide = false,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Local state
  const [sortingState, setSortingState] = useState<SortingState>(null);

  const storeSortingObject: SortingStateOption | null =
    params?.sort && JSON.parse(params.sort);
  //#endregion

  //#region LOGIC
  const sortingStateHandler = () => {
    if (sortName && sortChangedHandler) {
      if (sortingState === null) {
        sortChangedHandler({ column: sortName, order: "asc" });
        setSortingState("asc");
      } else if (sortingState === "asc") {
        sortChangedHandler({ column: sortName, order: "desc" });
        setSortingState("desc");
      } else {
        sortChangedHandler({ column: null, order: null });
        setSortingState(null);
      }
    }
  };
  //#endregion

  //#region RENDER
  return !hide ? (
    <th scope="col" className={className}>
      <div className="flex gap-1">
        <button
          disabled={!sortName || disabled}
          data-tooltip-id={headerText}
          data-tooltip-content={
            sortingState === null
              ? "Sort ascending"
              : sortingState === "asc"
              ? "Sort descening"
              : "Remove sorting"
          }
          data-tooltip-place="bottom"
          data-tooltip-variant="info"
          className={classNames(
            sortName && !disabled ? "font-bold hover:text-gray-400" : ""
          )}
          onClick={sortingStateHandler}
        >
          {t(headerText)}
        </button>
        {storeSortingObject?.order === "desc" &&
        storeSortingObject.column === sortName ? (
          <span>
            <ArrowDownCircleIcon className="w-5 h-5 text-emerald-500" />
          </span>
        ) : null}
        {storeSortingObject?.order === "asc" &&
        storeSortingObject.column === sortName ? (
          <span>
            <ArrowUpCircleIcon className="w-5 h-5 text-emerald-500" />
          </span>
        ) : null}
        {sortName && !disabled && <Tooltip id={headerText} />}
      </div>
    </th>
  ) : null;
  //#endregion
}
