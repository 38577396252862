//#region IMPORTS
import {
  BasketAddItemRequest,
  BasketRemoveItemRequest,
} from "../models/basket";
import { OrderApprove, OrderUpdate } from "../models/order";
import axios, { AxiosResponse } from "axios";
import { PaginatedResponse } from "../models/pagination";
import { StoreRequest } from "../models/store";
import { FieldValues } from "react-hook-form";
import getCookie from "../helpers/getCookieHelper";
//#endregion

//#region AXIOS DEFAULTS
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.withCredentials = true; weer aanzetten voor productie
//#endregion

//#region CHECK TOKEN
// FETCH SSO user
const ssoUserToken = getCookie("personal_access_token");
if (ssoUserToken) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + ssoUserToken;
}
//#endregion

//#region RESPONSEBODY
const responseBody = (response: AxiosResponse) => response.data;
//#endregion

//#region PAGINATION MIDDLEWARE
axios.interceptors.response.use(async (response) => {
  const pagination = response.headers["pagination"];
  if (pagination) {
    response.data = new PaginatedResponse(
      response.data,
      JSON.parse(pagination)
    );
  }
  return response;
});
//#endregion

//#region BASE REQUESTS
const requests = {
  get: (url: string, params?: URLSearchParams) =>
    axios.get(url, { params: params }).then(responseBody),
  post: (url: string, body?: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  patch: (url: string, body: {}) => axios.patch(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
  removeItem: (url: string, body?: {}) =>
    axios.delete(url, { data: { ...body } }).then(responseBody),
  upload: (url: string, body: FormData | null) =>
    axios
      .post(url, body, { headers: { "Content-type": "multipart/form-data" } })
      .then(responseBody),
  download: (url: string, params?: URLSearchParams) =>
    axios({
      url: url + "?" + params?.toString(),
      method: "GET",
      responseType: "blob",
    }).then(responseBody),
};
//#endregion

//#region CONVERT unrequired fields to dash
const createUnrequirdData = (item: any) => {
  let formData = { ...item };
  for (const key in item) {
    if (item[key] === "") {
      formData[key] = "-";
    }
  }
  return formData;
};
//#endregion

//#region PROMO ENDPOINTS
const Promos = {
  list: (params: URLSearchParams) => requests.get("Promos", params),
  detail: (id: number) => requests.get(`Promos/${id}`),
  update: (id: number, values: FieldValues) =>
    requests.put(`Promos/admin/${id}`, values),
  create: (values: FieldValues) => requests.post("Promos/admin", values),
  delete: (id: number) => requests.delete(`Promos/admin/${id}`),
  image: (id: number, values: FormData | null) =>
    requests.upload(`Promos/admin/${id}/upload`, values),
  createFromDataSource: (file: FormData, promo_id: number) => {
    return requests.post(`Bulk/import/rituals/${promo_id}`, file);
  },
};
//#endregion

//#region TRANSMART ENDPOINTS
const Transmart = {
  refreshAll: () => requests.get(`Transsmart/Check/All`),
  exportTrackAndTrace: (promo_id: number) => {
    return requests.download(
      `Orders/TrackAndTrace/${promo_id}/template/export`
    );
  },
  trackAndTraceUpdate: (file: FormData, promo_id: number) => {
    return requests.post(
      `Orders/TrackAndTrace/${promo_id}/template/import`,
      file
    );
  },
};
//#endregion

//#region PRODUCT ENDPOINTS
const Products = {
  list: (params: URLSearchParams) => requests.get("Products", params),
  detail: (id: number) => requests.get(`Products/${id}`),
  updateProduct: (id: number, data: any) =>
    requests.put(`Products/${id}`, createUnrequirdData(data)),
  createProduct: (data: any) => {
    return requests.post("Products", createUnrequirdData(data));
  },
  delete: (id: number) => requests.delete(`Products/${id}`),
  fetchFilters: () => requests.get("Products/Filters"),
};
//#endregion

//#region PRODUCTVERSIONS ENDPOINTS
const ProductVersions = {
  list: (params: URLSearchParams) => requests.get("Productversions", params),
  detail: (id: number) => requests.get(`Productversions/${id}`),
  updateProductVersion: (id: number, productversion: any) =>
    requests.put(`Productversions/${id}`, productversion),
  createProductVersion: (productversion: any) => {
    return requests.post("Productversions", productversion);
  },
  delete: (id: number) =>
    requests.delete(`Productversions/${id}`),
  fetchFilters: () => requests.get("Productversions/Filters"),
  image: (values: FormData) =>
    requests.post("Productversions/upload/basic", values),
};
//#endregion

//#region STOREMAGAGER ENDPOINTS
const StoreManagement = {
  list: (params: URLSearchParams) =>
    requests.get("StoreManagement/Stores", params),
  fetchFilters: () => requests.get("StoreManagement/Stores/filters"),
  detail: (id: number) => requests.get(`StoreManagement/Stores/${id}`),
  update: (id: number, data: StoreRequest) => {
    return requests.put(`StoreManagement/Stores/${id}`, data);
  },
  create: (data: any) => {
    return requests.post("StoreManagement/Stores", createUnrequirdData(data));
  },
  delete: (id: number) => requests.delete(`StoreManagement/Stores/${id}`),
  createFromDataSource: (file: FormData, customer_id: string) => {
    return requests.post(
      `StoreManagement/Stores/Excel/Import/${customer_id}`,
      file
    );
  },
  downloadTemplate: () =>
    requests.download("StoreManagement/Stores/Excel/Template"),
  downloadAllAsSpreadsheet: () =>
    requests.download("StoreManagement/EasyCatalog/Export"),
};

// STOREMANAGER COUNTRY ENDPOINTS
const CountryManagement = {
  list: (params?: URLSearchParams) =>
    requests.get("StoreManagement/Countries", params),
  detail: (id: number) => requests.get(`StoreManagement/Countries/${id}`),
  update: (id: number, data: FieldValues) => {
    return requests.put(
      `StoreManagement/Countries/${id}`,
      createUnrequirdData(data)
    );
  },
  create: (data: any) => {
    return requests.post(
      "StoreManagement/Countries",
      createUnrequirdData(data)
    );
  },
  delete: (id: number) => requests.delete(`StoreManagement/Countries/${id}`),
};

// STOREMANAGER CUSTOMER ENDPOINTS
const CustomerManagement = {
  list: (params?: URLSearchParams) =>
    requests.get("StoreManagement/Customers", params),
  detail: (id: number) => requests.get(`StoreManagement/Customers/${id}`),
  update: (id: number, data: FieldValues) => {
    return requests.put(
      `StoreManagement/Customers/${id}`,
      createUnrequirdData(data)
    );
  },
  create: (data: any) => {
    return requests.post(
      "StoreManagement/Customers",
      createUnrequirdData(data)
    );
  },
  delete: (id: number) => requests.delete(`StoreManagement/Customers/${id}`),
};

// STOREMANAGER LANGUAGE ENDPOINTS
const LanguagesManagement = {
  list: (params?: URLSearchParams) =>
    requests.get("StoreManagement/Languages", params),
  detail: (id: number) => requests.get(`StoreManagement/Languages/${id}`),
  update: (id: number, data: FieldValues) => {
    return requests.put(
      `StoreManagement/Languages/${id}`,
      createUnrequirdData(data)
    );
  },
  create: (data: any) => {
    return requests.post(
      "StoreManagement/Languages",
      createUnrequirdData(data)
    );
  },
  delete: (id: number) => requests.delete(`StoreManagement/Languages/${id}`),
};

// STOREMANAGER CUSTOMER ENDPOINTS
const StoreFormats = {
  list: () => requests.get("StoreManagement/StoreFormats"),
  detail: (id: number) => requests.get(`StoreManagement/StoreFormats/${id}`),
  update: (id: number, data: FieldValues) => {
    return requests.put(
      `StoreManagement/StoreFormats/${id}`,
      createUnrequirdData(data)
    );
  },
  create: (data: any) => {
    return requests.post(
      "StoreManagement/StoreFormats",
      createUnrequirdData(data)
    );
  },
  delete: (id: number) => requests.delete(`StoreManagement/StoreFormats/${id}`),
};

//#endregion

//#region ORDERS ENDPOINTS
const Orders = {
  list: (params?: URLSearchParams) => requests.get("Orders", params),
  listDetail: (params?: URLSearchParams) =>
    requests.get("Orders?include_sub_orders=1", params),
  detail: (id: number) => requests.get(`Orders/${id}`),
  listPromoIdShop: (promo_id: number, order_type: string) =>
    requests.get(`Orders?promo_id=${promo_id}&order_type=${order_type}`),
  createOrder: (values: any) => requests.post("Orders", values),
  fetchFilters: () => requests.get("Orders/filters"),
  fetchOpen: () => requests.get("Orders?order_status=Ordered"),
  listPromoId: (promoId: string) =>
    requests.get(`Orders/?orderpromos=${promoId}`),
  updateOrder: (id: number, values: OrderUpdate) => {
    return requests.put(`Orders/${id}`, values);
  },
  updateOrders: (values: OrderUpdate[]) => {
    return requests.patch(`Orders`, values);
  },
  updateMultiOrderStatus: (values: any) =>
    requests.post("Orders/Statuses/Multi/Orders", values),
  updateMultiSubOrderStatus: (values: any) =>
    requests.post("Orders/Statuses/Multi/SubOrders", values),
  approveOrder: (orders: { id: number; approved: boolean | null }[]) => {
    return requests.patch("Orders/approve", { orders });
  },
  approveOrders: (values: OrderApprove[]) => {
    return requests.patch(`Orders/approve`, [...values]);
  },
  changeItem: (
    orderitemid: number,
    quantity = 0,
    action: "decrease" | "increase"
  ) =>
    requests.post(`Orders/admin/orderitem/${orderitemid}/${action}`, {
      quantity,
    }),
  downloadspreadsheat: (params?: URLSearchParams) =>
    requests.download("Orders/admin/orderitem/export", params),
};
//#endregion

//#region SUBORDERS ENDPOINTS
const SubOrders = {
  list: (params?: URLSearchParams) => requests.get("Suborders", params),
  fetchFilters: () => requests.get("Suborders/filters"),
};
//#endregion

//#region BASKET ENDPOINTS
const Basket = {
  get: () => requests.get("Basket"),
  addItem: (values: BasketAddItemRequest) =>
    requests.post("Basket/items", values),
  removeItem: (values: BasketRemoveItemRequest) =>
    requests.removeItem("Basket/items", values),
  delete: (id: number) => requests.delete(`Basket/${id}`),
  listReasons: () => requests.get("Basket/Reasons"),
};
//#endregion

//#region TRANSLATION ENDPOINTS
const Translations = {
  list: (params?: URLSearchParams) =>
    requests.get("Internationalization/Translations/Lines", params),
  createOrUpdate: (values: any) =>
    requests.post("Internationalization/Translations/Lines", values),
  delete: (id: number) =>
    requests.delete(`Internationalization/Translations/Lines/${id}`),
  getAvailableLanguages: () => requests.get("Internationalization/Languages"),
  getCurrentListInExcel: () =>
    requests.download("Internationalization/Translations/Excel/Export"),
  createFromDataSource: (file: FormData) => {
    return requests.post(
      "Internationalization/Translations/Excel/Import",
      file
    );
  },
};
//#endregion

//#region ACCOUNT ENDPOINTS
const Account = {
  login: (values: any) => requests.post("Account/login", values),
  logout: () => requests.post("Account/logout"),
  register: (values: any) => requests.post("Account/register", values),
  currentUser: () => requests.get("Account/currentuser"),
};
//#endregion

//#region USER ENDPOINTS
const Users = {
  list: (params: URLSearchParams) => requests.get("Users", params),
  detail: (id: number) => requests.get(`Users/${id}`),
  roles: () => requests.get("Users/roles"),
  fetchFilters: () => requests.get("Users/filters"),
  registerUser: (values: any) => requests.post("Users", values),
  delete: (id: number) => requests.delete(`Users/${id}`),
  updateUser: (id: number, user: any) => requests.put(`Users/${id}`, user),
  updatePassword: (id: number, values: object) =>
    requests.put(`Users/${id}/password/change`, values),
};
//#endregion

//#region REPORTS ENDPOINTS
const Reports = {
  download: (
    action: "Reports/DeliveryList" | "Reports/PickList",
    id: number | undefined
  ) =>
    axios.get<Blob>(`${action}/${id}`, {
      responseType: "blob",
    }),
  emailMeListsForSelectedOrders: (values: any) =>
    requests.post("Reports/Combined/Multi/Start", values),
};
//#endregion

//#region EXPORTS
const agent = {
  LanguagesManagement,
  CustomerManagement,
  CountryManagement,
  StoreManagement,
  ProductVersions,
  Translations,
  StoreFormats,
  SubOrders,
  Transmart,
  Products,
  Reports,
  Account,
  Promos,
  Orders,
  Basket,
  Users,
};
export default agent;
//#endregion
