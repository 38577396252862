import { Icon } from "../../models/icon";

function Stargazer({ color = "#334155", width = 32 }: Icon) {
  return (
    <svg viewBox="0 0 1220 1082.96" style={{ width: width + "px" }}>
      <path
        fill={color}
        d="M102.5,695c3.08,7.4,8.84,13.18,16.24,16.24,3.72,1.52,7.6,2.3,11.5,2.3s7.78-.78,11.44-2.3l18.48-7.66c7.4-3.08,13.18-8.84,16.24-16.24,1.74-4.21,2.49-8.66,2.25-13.08l276.17-114.4c5.1-2.1,7.52-7.96,5.42-13.06-2.1-5.1-7.96-7.5-13.06-5.42l-274.58,113.74-61.24-147.82,341.84-141.59,27.22,65.69c1.6,3.86,5.32,6.18,9.24,6.18,1.28,0,2.58-.24,3.82-.76,5.1-2.1,7.52-7.96,5.42-13.06l-27.22-65.71,55.42-22.96,21.02,50.76c2.1,5.1,7.98,7.52,13.06,5.42,5.1-2.1,7.52-7.96,5.42-13.06l-24.61-59.42c-.07-.2-.13-.4-.21-.6-.09-.22-.2-.43-.3-.65l-11.2-27.03c-1.02-2.48-1.02-5.18,0-7.66s2.94-4.4,5.42-5.42L923.74,116.71l99.49,240.22-267.4,110.75c-5.1,2.1-7.52,7.96-5.42,13.06,1.6,3.86,5.32,6.18,9.24,6.18,1.28,0,2.58-.24,3.82-.74l276.5-114.53s.09-.03,.14-.05c.05-.02,.1-.05,.16-.08l64.52-26.72,7.64,18.46c3.06,7.42,8.84,13.18,16.24,16.24,3.7,1.52,7.6,2.3,11.48,2.3s7.78-.78,11.5-2.3l18.46-7.66c7.42-3.06,13.18-8.84,16.24-16.24,3.06-7.4,3.06-15.56,0-22.96L1056.26,18.52c-6.34-15.28-23.94-22.56-39.2-16.24l-18.48,7.66c-15.28,6.32-22.56,23.9-16.24,39.2l7.65,18.48-461.95,191.34c-7.42,3.06-13.18,8.84-16.24,16.24-3.06,7.4-3.06,15.56,0,22.96l7.65,18.46L102.1,489.49c-8.28-9.18-21.75-12.63-33.78-7.65l-18.46,7.66c-7.42,3.06-13.18,8.84-16.24,16.24-3.06,7.4-3.06,15.56,0,22.96l68.88,166.3ZM1006.24,28.44l18.46-7.66c1.26-.52,2.54-.76,3.82-.76,3.92,0,7.66,2.32,9.26,6.16l130.12,314.16c1.02,2.48,1.02,5.18,0,7.66-1.02,2.46-2.94,4.38-5.4,5.4l-18.48,7.66c-2.46,1.02-5.18,1.02-7.66,0-2.48-1.02-4.4-2.94-5.42-5.42l-92.12-222.37-26.52-64.05s-.03-.06-.05-.1l-11.43-27.6c-2.1-5.1,.32-10.98,5.42-13.08Zm35.47,320.84l-99.49-240.22,55.42-22.96,99.5,240.22-55.43,22.96ZM52.1,513.38c1.02-2.46,2.94-4.38,5.42-5.4l18.48-7.66c1.26-.52,2.54-.76,3.82-.76,3.94,0,7.66,2.32,9.24,6.18l68.88,166.3c1.02,2.46,1.02,5.18,0,7.64-1.02,2.48-2.94,4.4-5.42,5.42l-18.48,7.66c-5.12,2.12-10.96-.34-13.06-5.42l-68.88-166.3c-1.02-2.48-1.02-5.18,0-7.66Z"
      />
      <path
        fill={color}
        d="M740,532.96c0-71.68-58.32-130-130-130s-130,58.32-130,130,58.32,130,130,130,130-58.32,130-130Zm-130-110c60.66,0,110,49.34,110,110s-49.34,110-110,110-110-49.34-110-110,49.34-110,110-110Z"
      />
      <path
        fill={color}
        d="M527.56,668.48c2.4-4.98,.3-10.94-4.68-13.34-5-2.42-10.96-.3-13.34,4.68l-175.6,365.84c-9.54,19.86-1.14,43.8,18.76,53.36,5.58,2.66,11.44,3.92,17.24,3.92,14.92,0,29.26-8.38,36.12-22.66l176.32-367.36c2.4-4.98,.3-10.94-4.68-13.34-5-2.4-10.96-.3-13.34,4.68l-176.32,367.36c-4.78,9.94-16.76,14.12-26.7,9.38-9.94-4.78-14.14-16.74-9.38-26.68l175.6-365.84Z"
      />
      <path
        fill={color}
        d="M813.94,1060.26c6.6,13.78,20.78,22.7,36.08,22.7,6,0,11.82-1.32,17.24-3.96,19.9-9.56,28.3-33.48,18.76-53.36l-175.6-365.84c-2.4-4.98-8.36-7.1-13.34-4.68-4.98,2.4-7.08,8.36-4.68,13.34l175.6,365.84c4.78,9.94,.56,21.9-9.36,26.68-9.8,4.7-22.1,.22-26.7-9.38l-176.32-367.36c-2.4-4.98-8.34-7.1-13.34-4.68-4.98,2.4-7.08,8.36-4.68,13.34l176.34,367.36Z"
      />
      <path
        fill={color}
        d="M700.36,902.96c1.54,0,3-.36,4.31-.99,3.36-1.61,5.69-5.04,5.69-9.01,0-5.52-4.48-10-10-10h-180.7c-5.52,0-10,4.48-10,10,0,3.96,2.31,7.37,5.64,8.99,1.32,.64,2.79,1.01,4.36,1.01h180.7Z"
      />
      <path
        fill={color}
        d="M1210,882.96h-354.34c-5.52,0-10,4.48-10,10s4.48,10,10,10h354.34c5.52,0,10-4.48,10-10s-4.48-10-10-10Z"
      />
      <path
        fill={color}
        d="M364.34,902.96c5.54,0,10-4.48,10-10s-4.48-10-10-10H10c-5.52,0-10,4.48-10,10s4.48,10,10,10H364.34Z"
      />
      <path
        fill={color}
        d="M610,602.96c38.6,0,70-31.4,70-70s-31.4-70-70-70-70,31.4-70,70,31.4,70,70,70Zm0-120c27.56,0,50,22.44,50,50s-22.44,50-50,50-50-22.44-50-50,22.44-50,50-50Z"
      />
      <path
        fill="#fbb033"
        d="M1038.32,615.18c-9.64-3.22-17.3-10.9-20.52-20.52l-8.3-24.88c-2.72-8.16-16.24-8.16-18.96,0l-8.3,24.88c-3.22,9.64-10.9,17.3-20.52,20.52l-24.88,8.3c-4.08,1.36-6.84,5.18-6.84,9.48s2.76,8.12,6.84,9.48l24.88,8.3c9.62,3.2,17.28,10.86,20.5,20.5l8.3,24.88c1.36,4.08,5.18,6.84,9.48,6.84s8.12-2.76,9.46-6.82l8.3-24.88c3.22-9.64,10.9-17.3,20.52-20.52l24.88-8.3c4.08-1.36,6.84-5.18,6.84-9.48s-2.76-8.12-6.8-9.48l-24.88-8.3Zm-38.32,46.52c-5.32-13-15.76-23.44-28.74-28.74,12.98-5.32,23.42-15.76,28.74-28.74,5.32,12.98,15.76,23.42,28.74,28.74-12.98,5.32-23.42,15.74-28.74,28.74Z"
      />
      <path
        fill="#fbb033"
        d="M267.42,160l24.88,8.3c9.64,3.22,17.3,10.9,20.52,20.52l8.3,24.88c1.36,4.08,5.18,6.84,9.48,6.84s8.14-2.74,9.48-6.86l8.3-24.88c3.2-9.64,10.86-17.3,20.5-20.52l24.88-8.3c4.08-1.36,6.84-5.18,6.84-9.48s-2.76-8.12-6.84-9.48l-24.88-8.3c-9.62-3.2-17.28-10.86-20.5-20.5l-8.3-24.88c-2.72-8.16-16.24-8.16-18.96,0l-8.3,24.88c-3.22,9.64-10.9,17.3-20.52,20.52l-24.88,8.3c-4.08,1.36-6.84,5.18-6.84,9.48s2.76,8.12,6.84,9.48Zm63.18-38.2c5.32,13,15.74,23.42,28.74,28.74-13,5.32-23.42,15.76-28.74,28.74-5.32-12.98-15.76-23.42-28.74-28.74,13-5.3,23.42-15.74,28.74-28.74Z"
      />
    </svg>
  );
}

export default Stargazer;
