//#region IMPORTS
import MainFilterBarFilteredListItem from "./MainFilterBarFilteredListItem";
//#endregion

//#region INTERFACE
interface Props {
  clearAllFiltersHandler?: () => void;
  clearFilterHandler: (paramName: string) => void;
  paramNamesList?: object;
}
//#endregion

export default function MainFilterBarFilteredList({
  clearAllFiltersHandler,
  clearFilterHandler,
  paramNamesList,
}: Props) {
  //#region SETUP
  const list = paramNamesList && Object.entries(paramNamesList);
  //#endregion

  //#region RENDER
  return list && list.length > 0 ? (
    <div className="flex justify-between items-center gap-8 w-full">
      <div className="flex gap-4 items-center">
        {list.map(([key, value]) => (
          <MainFilterBarFilteredListItem
            clearFilterHandler={(name: string) => clearFilterHandler(name)}
            key={key}
            name={key}
            value={value}
            hide={value === undefined}
          />
        ))}
      </div>
      {list.length > 1 ? (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-500 ring-1 ring-inset ring-red-500/20">
          {`clear ${
            list.filter(([_, value]) => value !== undefined).length
          } filter${list.length > 1 ? "s" : ""}`}
          <button
            type="button"
            onClick={clearAllFiltersHandler}
            className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-500/20"
          >
            <svg
              viewBox="0 0 14 14"
              className="h-3.5 w-3.5 stroke-red-500/50 group-hover:stroke-red-500/75"
            >
              <path d="M4 4l6 6m0-6l-6 6" />
            </svg>
            <span className="absolute -inset-1" />
          </button>
        </span>
      ) : null}
    </div>
  ) : null;
  //#endregion
}
