//#region IMPORTS
import {
  setOrderParams,
  clearOrderParam,
  clearOrderParams,
} from "../../features/orders/ordersSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { ReactNode } from "react";
import MainFilterBarItem from "../_layout/MainFilterBarItem";
import MainFilterBar from "../_layout/MainFilterBar";
//#endregion

//#region INTERFACE
interface Props {
  extensionButtons?: ReactNode;
}
//#endregion

export default function OrdersFilterBar({ extensionButtons }: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { page, limit, sort, include_sub_orders, ...params } = useAppSelector(
    (state) => state.orders.order_params
  );
  const { filters, filters_loaded } = useAppSelector((state) => state.orders);
  //#endregion

  //#region RENDER
  return (
    <MainFilterBar
      paramNamesList={params}
      clearAllFiltersHandler={() => dispatch(clearOrderParams())}
      extensionButtons={extensionButtons}
      clearFilterHandler={(paramName: string) => {
        dispatch(clearOrderParam(paramName));
      }}
      disabled={!filters_loaded}
    >
      {/* order_code */}
      <MainFilterBarItem
        filterParameterName="order_code"
        displayExplanation="Search the orders by order code"
        clearFilterAction={() => dispatch(clearOrderParam("order_code"))}
        selectedValue={params.order_code}
        filterAction={(value: string) =>
          dispatch(setOrderParams({ order_code: value }))
        }
        displayText="Order code"
        selected={!!params.order_code}
      />
      {/* promo_code */}
      <MainFilterBarItem
        filterParameterName="promo_code"
        displayExplanation="Filter the orders by promo code"
        clearFilterAction={() => dispatch(clearOrderParam("promo_code"))}
        selectBoxItems={filters.promo_code.filter((item) => item !== null)}
        selectedValue={params.promo_code}
        filterAction={(value: string) => {
          dispatch(setOrderParams({ promo_code: value }));
        }}
        displayText="Promo code"
        selectBox
        selected={!!params.promo_code}
      />

      {/* city */}
      <MainFilterBarItem
        filterParameterName="city"
        displayExplanation="Filter the orders by city"
        clearFilterAction={() => dispatch(clearOrderParam("city"))}
        selectBoxItems={filters.city.filter((item) => item !== null)}
        selectedValue={params.city}
        filterAction={(value: string) => {
          dispatch(setOrderParams({ city: value }));
        }}
        displayText="City"
        selectBox
        selected={!!params.city}
      />

      {/* country_name */}
      <MainFilterBarItem
        filterParameterName="country_name"
        displayExplanation="Filter the orders by country name"
        clearFilterAction={() => dispatch(clearOrderParam("country_name"))}
        selectBoxItems={filters.country_name.filter((item) => item !== null)}
        selectedValue={params.country_name}
        filterAction={(value: string) => {
          dispatch(setOrderParams({ country_name: value }));
        }}
        displayText="Country name"
        selectBox
        selected={!!params.country_name}
      />

      {/* language */}
      <MainFilterBarItem
        filterParameterName="language"
        displayExplanation="Filter the orders by language"
        clearFilterAction={() => dispatch(clearOrderParam("language"))}
        selectBoxItems={filters.language.filter((item) => item !== null)}
        selectedValue={params.language}
        filterAction={(value: string) => {
          dispatch(setOrderParams({ language: value }));
        }}
        displayText="Language"
        selectBox
        selected={!!params.language}
      />

      {/* order_status */}
      <MainFilterBarItem
        filterParameterName="order_status"
        displayExplanation="Filter the orders by order status"
        clearFilterAction={() => dispatch(clearOrderParam("order_status"))}
        selectBoxItems={filters.order_status.filter((item) => item !== null)}
        selectedValue={params.order_status}
        filterAction={(value: string) => {
          dispatch(setOrderParams({ order_status: value }));
        }}
        displayText="Order status"
        selectBox
        selected={!!params.order_status}
      />

      {/* order_type */}
      <MainFilterBarItem
        filterParameterName="order_type"
        displayExplanation="Filter the orders by order type"
        clearFilterAction={() => dispatch(clearOrderParam("order_type"))}
        selectBoxItems={filters.order_type.filter((item) => item !== null)}
        selectedValue={params.order_type}
        filterAction={(value: string) => {
          dispatch(setOrderParams({ order_type: value }));
        }}
        displayText="Order type"
        selectBox
        selected={!!params.order_type}
      />
    </MainFilterBar>
  );
  //#endregion
}
