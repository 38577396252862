//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { setUserPageNumber } from "../../features/users/usersSlice";
import { MetaData } from "../../models/pagination";
import { User } from "../../models/user";
import useAdminManager from "../../hooks/useAdminManager";
import UsersTableHeader from "./UsersTableHeader";
import UsersTableRow from "./UsersTableRow";
import MainTable from "../_layout/MainTable";
//#endregion

//#region INTERFACE
interface Props {
  deleteHandler: (user: User) => void;
  detailHandler: (user: User) => void;
  usersLoaded: boolean;
  metaData?: MetaData;
  users: User[];
  hide?: boolean;
}
//#endregion

export default function UsersTable({
  deleteHandler,
  detailHandler,
  usersLoaded,
  metaData,
  users,
  hide = false,
}: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);

  // Roles
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region RENDER
  return (
    <MainTable
      itemsLoaded={usersLoaded}
      metaData={metaData}
      header={<UsersTableHeader />}
      onPageChangeHandler={(page: number) =>
        dispatch(setUserPageNumber({ page: page }))
      }
      hide={hide}
    >
      {users.map((user) => (
        <UsersTableRow
          deleteHandler={(user: User) => deleteHandler(user)}
          detailHandler={(user: User) => detailHandler(user)}
          isAdmin={isAdmin}
          user={user}
          key={user.id}
        />
      ))}
    </MainTable>
  );
  //#endregion
}
