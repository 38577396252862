//#region IMPORTS
import { clearOrderParams, fetchOrdersAsync } from "./ordersSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useEffect } from "react";
import usePickerManager from "../../hooks/usePickerManager";
import OrdersContainer from "../../components/orders/OrdersContainer";
import useAdminManager from "../../hooks/useAdminManager";
//#endregion

//#region INTERFACE
interface Props {
  title: string;
}
//#endregion

export default function OrdersHistoryPage({ title }: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { orders_loaded } = useAppSelector((state) => state.orders);
  const { user } = useAppSelector((state) => state.account);

  // Roles
  const isPicker = usePickerManager(user);
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region LOGIC
  // Set page specific filters
  useEffect(() => {
    !isPicker && !isAdmin && dispatch(clearOrderParams());
  }, [dispatch, isAdmin, isPicker]);

  // Load orders
  useEffect(() => {
    const fetchOnload = async () => {
      if (!orders_loaded) {
        await dispatch(fetchOrdersAsync());
      }
    };
    fetchOnload();
  }, [orders_loaded, dispatch]);
  //#endregion

  return <OrdersContainer title={title} />;
}
