//#region IMPORTS
import { Product } from "../../models/product";
import MainTableRowActionDelete from "../_layout/MainTableRowActionDelete";
import MainTableRowActionRead from "../_layout/MainTableRowActionRead";
//#endregion

//#region INTERFACE
interface Props {
  deleteHandler: (product: Product) => void;
  detailHandler: (product: Product) => void;
  isAdmin: boolean;
  item: Product;
}
//#endregion

export default function ProductsTableRow({
  deleteHandler,
  detailHandler,
  isAdmin = false,
  item,
}: Props) {
  //#region RENDER
  return (
    <tr className="border-t border-slate-300 h-10">
      {/* actions */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-500 sm:pl-0">
        <MainTableRowActionRead
          detailHandler={() => detailHandler(item)}
          tooltipId={String(item.number)}
        />
      </td>
      {/* number */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-500 sm:pl-0">
        {item.number}
      </td>

      {/* name */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-500 sm:pl-0">
        {item.name}
      </td>

      {/* length */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
        {item.length}
      </td>

      {/* width */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
        {item.width}
      </td>

      {/* specs_material */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
        {item.specs_material}
      </td>

      {/* specs_sided */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
        {item.specs_sided}
      </td>

      {/* Delete */}
      <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
        <MainTableRowActionDelete
          deleteHandler={() => deleteHandler(item)}
          hide={!isAdmin}
        />
      </td>
    </tr>
  );
  //#endregion
}
