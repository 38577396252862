//#region IMPORTS
import {
  productVersionsSelectors,
  setProductVersionPageNumber,
} from "../../features/productVersions/productVersionsSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { ProductVersion } from "../../models/productVersion";
import { MetaData } from "../../models/pagination";
import ProductVersionsTableHeader from "./ProductVersionsTableHeader";
import ProductVersionsTableRow from "./ProductVersionsTableRow";
import useAdminManager from "../../hooks/useAdminManager";
import MainTable from "../_layout/MainTable";
//#endregion

//#region INTERFACE
interface Props {
  deleteHandler: (productVersion: ProductVersion) => void;
  detailHandler: (productVersion: ProductVersion) => void;
  itemsLoaded: boolean;
  metaData?: MetaData;
  hide?: boolean;
}
//#endregion

export default function ProductVersionsTable({
  deleteHandler,
  detailHandler,
  itemsLoaded,
  metaData,
  hide = false,
}: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);
  const items = useAppSelector(productVersionsSelectors.selectAll);

  // Roles
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region RENDER
  return (
    <MainTable
      itemsLoaded={itemsLoaded}
      metaData={metaData}
      onPageChangeHandler={(page: number) =>
        dispatch(setProductVersionPageNumber({ page: page }))
      }
      header={<ProductVersionsTableHeader />}
      hide={hide}
    >
      {items.map((productVersion) => (
        <ProductVersionsTableRow
          deleteHandler={(productVersion: ProductVersion) =>
            deleteHandler(productVersion)
          }
          detailHandler={(productVersion: ProductVersion) =>
            detailHandler(productVersion)
          }
          isAdmin={isAdmin}
          item={productVersion}
          key={productVersion.id}
        />
      ))}
    </MainTable>
  );
  //#endregion
}
