//#region IMPORTS
import {
  setProductParams,
  clearProductParam,
  clearProductParams,
} from "../../features/products/productsSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import MainFilterBarItem from "../_layout/MainFilterBarItem";
import MainFilterBar from "../_layout/MainFilterBar";
//#endregion

export default function ProductsFilterBar() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { page, limit, sort, ...params } = useAppSelector(
    (state) => state.products.product_params
  );
  const { filters, filters_loaded } = useAppSelector((state) => state.products);
  //#endregion

  //#region RENDER
  return (
    <MainFilterBar
      paramNamesList={params}
      clearAllFiltersHandler={() => dispatch(clearProductParams())}
      clearFilterHandler={(paramName: string) => {
        dispatch(clearProductParam(paramName));
      }}
      disabled={!filters_loaded}
    >
      {/* name */}
      <MainFilterBarItem
        filterParameterName="store_name"
        displayExplanation="Search the products by name"
        clearFilterAction={() => dispatch(clearProductParam("name"))}
        selectedValue={params.name}
        filterAction={(value: string) =>
          dispatch(setProductParams({ name: value }))
        }
        displayText="Product name"
        selected={!!params.name}
      />

      {/* number */}
      <MainFilterBarItem
        filterParameterName="number"
        displayExplanation="Search the products by code"
        clearFilterAction={() => dispatch(clearProductParam("number"))}
        selectedValue={params.number}
        filterAction={(value: string) =>
          dispatch(setProductParams({ number: value }))
        }
        displayText="Code"
        selected={!!params.number}
      />

      {/* produced_by */}
      <MainFilterBarItem
        filterParameterName="city"
        displayExplanation="Filter the products by producer"
        clearFilterAction={() => dispatch(clearProductParam("produced_by"))}
        selectBoxItems={filters.producer.filter((item) => item !== null)}
        selectedValue={params.produced_by}
        filterAction={(value: string) => {
          dispatch(setProductParams({ produced_by: value }));
        }}
        displayText="Producer"
        selectBox
        selected={!!params.produced_by}
      />
    </MainFilterBar>
  );
  //#endregion
}
