//#region IMPORTS
import {
  fetchFilters,
  usersSelectors,
  deleteUserAsync,
  fetchUserRolesAsync,
} from "../../features/users/usersSlice";
import { CREATE_OR_UPDATE_USER, DELETE_USER_SURE } from "../../models/_consts";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { openModal } from "../../features/dashBoard/dashboardSlice";
import { User } from "../../models/user";
import useAdminManager from "../../hooks/useAdminManager";
import UsersFilterBar from "./UsersFilterBar";
import MainContainer from "../_layout/MainContainer";
import PageHeader from "../genericPageHeader/PageHeader";
import AreYouSure from "../modalTypes/AreYouSure";
import UsersTable from "./UsersTable";
import MainModal from "../_layout/MainModal";
import UserForm from "./UserForm";
import agent from "../../api/agent";
//#endregion

export default function UsersContainer() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const users = useAppSelector(usersSelectors.selectAll);
  const { user } = useAppSelector((state) => state.account);
  const { users_loaded, userRoles_loaded, filters_loaded, metaData } =
    useAppSelector((state) => state.users);

  // Local State
  const [userToManage, setUserToManage] = useState<User>();

  // Roles
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region LOGIC
  // Load filters
  useEffect(() => {
    const fetchFiltering = async () => {
      await dispatch(fetchFilters());
    };
    !filters_loaded && fetchFiltering();
  }, [filters_loaded, dispatch]);

  // Load roles
  useEffect(() => {
    const fetchRoles = async () => {
      await dispatch(fetchUserRolesAsync());
    };
    !userRoles_loaded && fetchRoles();
  }, [userRoles_loaded, dispatch]);

  // Create new user
  const createNewUser = () => {
    setUserToManage(undefined);
    dispatch(openModal(CREATE_OR_UPDATE_USER));
  };

  // Read a user
  const detailHandler = async (id: number) => {
    const { data: user } = await agent.Users.detail(id);
    setUserToManage(user);
    dispatch(openModal(CREATE_OR_UPDATE_USER));
  };

  // Set Delete user warning
  const deleteUserWarningHandler = async (id: number) => {
    const { data: user } = await agent.Users.detail(id);
    setUserToManage(user);
    dispatch(openModal(DELETE_USER_SURE));
  };

  // Delete User
  const deleteUserHandler = async () => {
    userToManage && (await dispatch(deleteUserAsync({ id: userToManage.id })));
    cancelCUD();
  };

  // Cancel create or update promo
  const cancelCUD = () => {
    dispatch(openModal(false));
    setUserToManage(undefined);
  };
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* MODALS */}
      <MainModal type={DELETE_USER_SURE} closeHandler={cancelCUD}>
        <AreYouSure
          confirmHandler={deleteUserHandler}
          closeHandler={cancelCUD}
          message={`Are you sure you want to delete user ${userToManage?.username}`}
          title="Delete user?"
        />
      </MainModal>

      <MainModal type={CREATE_OR_UPDATE_USER} closeHandler={cancelCUD}>
        <UserForm cancelCUD={cancelCUD} isAdmin={isAdmin} user={userToManage} />
      </MainModal>

      {/* BODY */}
      <MainContainer>
        <PageHeader
          createButtonValue={t("rituals.add")}
          createClickHandler={createNewUser}
          isAdmin={isAdmin}
          create={false}
          title={t("rituals.users")}
        />

        <UsersFilterBar />

        <UsersTable
          deleteHandler={(user: User) => deleteUserWarningHandler(user.id)}
          detailHandler={(user: User) => detailHandler(user.id)}
          usersLoaded={users_loaded}
          metaData={metaData}
          users={users}
          hide={!users_loaded && !filters_loaded}
        />
      </MainContainer>
    </Fragment>
  );
  //#endregion
}
