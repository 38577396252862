//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { SortingStateOption } from "../../models/sorting";
import { setOrderParams } from "../../features/orders/ordersSlice";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import MainTableHeaderCell from "../_layout/MainTableHeaderCell";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import useHQmanager from "../../hooks/useHQmanager";
//#endregion

//#region INTERFACE
interface Props {
  toggleAll: () => void;
  checked: boolean;
}
//#endregion

export default function OrdersTableHeader({ toggleAll, checked }: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Ref
  const checkbox = useRef<any>();

  // Redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);
  const { order_params, filters_loaded } = useAppSelector(
    (state) => state.orders
  );

  // Roles
  const isPicker = usePickerManager(user);
  const isHQTeam = useHQmanager(user);
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region LOGIC

  // Set order sorting
  const sortChangedHandler = (option: SortingStateOption) => {
    if (option.column !== null) {
      dispatch(
        setOrderParams({
          sort: JSON.stringify(option),
        })
      );
    } else {
      dispatch(setOrderParams({ sort: undefined }));
    }
  };

  //#endregion

  //#region RENDER
  return (
    <thead>
      <tr>
        {/* Checkbox */}
        <th
          className="whitespace-nowrap pr-4 py-3.5 w-8 text-left text-xs font-semibold text-gray-900"
          scope="col"
        >
          <input
            style={{ opacity: isAdmin || isPicker || isHQTeam ? 1 : 0 }}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
            ref={checkbox}
            checked={checked}
            onChange={toggleAll}
          />
        </th>

        {/* Read */}
        <th
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs font-semibold text-gray-900"
          scope="col"
        />

        {/* Store */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.store")}
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          disabled={!filters_loaded}
          sortName="shop_name"
          params={order_params}
          hide={!isHQTeam}
        />

        {/* Project */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.project")}
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          disabled={!filters_loaded}
          sortName="promo_id"
          params={order_params}
        />

        {/* Order date */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.orderDate")}
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          disabled={!filters_loaded}
          sortName="order_date"
          params={order_params}
        />

        {/* Ordercode */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.orderCode")}
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          sortName="order_code"
          disabled={!filters_loaded}
          params={order_params}
          hide={isHQTeam}
        />

        {/* Ordertype */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.orderType")}
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          disabled={!filters_loaded}
          sortName="order_type"
          params={order_params}
        />

        {/* Orderstatus */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.orderStatus")}
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900 relative z-10"
          disabled={!filters_loaded}
          sortName="order_status"
          params={order_params}
        />

        {/* Items */}
        <MainTableHeaderCell
          headerText={t("rituals.items")}
          className="whitespace-nowrap pr-4 py-3.5 text-left w-64 text-xs text-gray-900"
        />

        {/* Comment */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.comment")}
          className="whitespace-nowrap pr-4 py-3.5 text-left w-24 text-xs text-gray-900"
          disabled={!filters_loaded}
          sortName="comment"
          params={order_params}
        />

        {/* Parcel info */}
        <MainTableHeaderCell
          headerText={t("rituals.parcelInfo")}
          className="whitespace-nowrap pr-4 py-3.5 text-left w-72 text-xs text-gray-900"
        />

        {/* Reasons */}
        <MainTableHeaderCell
          headerText={t("rituals.reason")}
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          hide={!isHQTeam}
        />

        {/* Decline */}
        <MainTableHeaderCell
          headerText="decline"
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
        />

        {/* Actions */}
        <MainTableHeaderCell
          headerText="actions"
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
        />
      </tr>
    </thead>
  );
  //#endregion
}
