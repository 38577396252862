//#region IMPORTS
import { useState } from "react";
import { Tooltip } from "react-tooltip";
//#endregion

//#region INTERFACE
interface Props {
  clearFilterAction: () => void;
  selectedValue?: string;
  filterAction: (value?: string) => void;
}
//#endregion

export default function MainFilterBarToggleActiveItem({
  clearFilterAction,
  selectedValue,
  filterAction,
}: Props) {
  //#region SETUP
  const [value, setValue] = useState<string | undefined>(selectedValue);
  //#endregion
  //#region RENDER
  return (
    <div className="group relative pl-8 -mx-3 rounded-lg p-3 hover:bg-gray-100 flex flex-col justify-between">
      <div>
        <p className="text-slate-700 text-base font-medium">Status</p>
        <p className="mt-1 text-gray-400 text-xs">
          Filter the by active or disabled status
        </p>
      </div>
      <fieldset className="flex h-10 flex-col gap-1 2xl:flex-row 2xl:gap-4">
        <div className="flex items-center gap-1">
          <input
            checked={value === undefined}
            data-tooltip-id="showAllItems"
            data-tooltip-content="Show all"
            id="all"
            name="statusMethod"
            type="radio"
            className="h-4 w-4 border-gray-300 text-slate-400 focus:ring-slate-500"
            onChange={() => {
              setValue(undefined);
              clearFilterAction();
            }}
          />
          {value !== undefined && <Tooltip id="showAllItems" />}
          <label
            htmlFor="all"
            className="ml-1 block text-sm font-medium leading-6 text-gray-900"
          >
            All
          </label>
        </div>

        <div className="flex items-center gap-1">
          <input
            checked={value === "Yes"}
            id="activeOnly"
            name="statusMethod"
            type="radio"
            className="h-4 w-4 border-gray-300 text-amber-400 focus:ring-amber-500"
            onChange={() => {
              setValue("Yes");
              filterAction("Yes");
            }}
          />
          <label
            htmlFor="activeOnly"
            className="ml-1 block text-sm font-medium text-gray-900"
          >
            Active
          </label>
        </div>

        <div className="flex items-center gap-1">
          <input
            checked={value === "No"}
            id="activeOnly"
            name="statusMethod"
            type="radio"
            className="h-4 w-4 border-gray-300 text-amber-400 focus:ring-amber-500"
            onChange={() => {
              setValue("No");
              filterAction("No");
            }}
          />
          <label
            htmlFor="activeOnly"
            className="ml-1 block text-sm font-medium text-gray-900"
          >
            Disabled
          </label>
        </div>
      </fieldset>
    </div>
  );
  //#endregion
}
