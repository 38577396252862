//#region IMPORTS
import {
  setPageNumber,
  setSelectedOrders,
} from "../../features/orders/ordersSlice";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Order, ORDERSTATUS, ORDERTYPE } from "../../models/order";
import { MetaData } from "../../models/pagination";
import OrdersTableHeader from "./OrdersTableHeader";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import OrdersTableRow from "./OrdersTableRow";
import useHQmanager from "../../hooks/useHQmanager";
import MainTable from "../_layout/MainTable";
//#endregion

//#region INTERFACE
interface Props {
  changeOrderStatusHandler: (order: Order, status: string) => void;
  itemsLoaded?: boolean;
  metaData?: MetaData;
  items: Order[];
  hide?: boolean;
}
//#endregion

export default function OrdersTable({
  changeOrderStatusHandler,
  itemsLoaded = false,
  metaData,
  items = [],
  hide = false,
}: Props) {
  //#region SETUP
  // Ref
  const checkbox = useRef<any>();

  // Redux
  const dispatch = useAppDispatch();
  const { selectedOrders } = useAppSelector((state) => state.orders);
  const { user } = useAppSelector((state) => state.account);

  // Roles
  const isPicker = usePickerManager(user);
  const isHQTeam = useHQmanager(user);
  const isAdmin = useAdminManager(user);

  // Local state
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  //#endregion

  //#region LOGIC
  useEffect(() => {
    if (!itemsLoaded) {
      dispatch(setSelectedOrders([]));
    }
  }, [itemsLoaded, dispatch]);

  // Change table on the fly
  useLayoutEffect(() => {
    const isIndeterminate =
      selectedOrders.length > 0 && selectedOrders.length < items.length;
    setChecked(selectedOrders.length === items.length);
    setIndeterminate(isIndeterminate);
    if (isAdmin || isPicker || isHQTeam) {
      if (checkbox.current) {
        checkbox.current.indeterminate = isIndeterminate;
      }
    }
  }, [selectedOrders, items, checkbox, isPicker, isAdmin, isHQTeam]);

  // Toggle all backorders
  const toggleAll = () => {
    dispatch(
      setSelectedOrders(
        checked || indeterminate
          ? []
          : isAdmin || isPicker
          ? items.filter((order) => order.promo_id)
          : items.filter(
              (order) =>
                // select pending orders to approve
                (order.order_type !== ORDERTYPE.original &&
                  order.order_status === ORDERSTATUS.pending &&
                  order.order_status !== ORDERSTATUS.cancelled) ||
                // select orders to ship
                (order.order_type !== ORDERTYPE.original &&
                  order.order_status === ORDERSTATUS.ordered &&
                  order.order_status !== ORDERSTATUS.cancelled)
            )
      )
    );
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };
  //#endregion

  //#region RENDER
  return (
    <MainTable
      itemsLoaded={itemsLoaded}
      metaData={metaData}
      onPageChangeHandler={(page: number) =>
        dispatch(setPageNumber({ page: page }))
      }
      header={<OrdersTableHeader toggleAll={toggleAll} checked={checked} />}
      hide={hide}
    >
      {items.map((order) => (
        <OrdersTableRow
          clearSelectedOrdersHandler={() => dispatch(setSelectedOrders([]))}
          changeOrderStatusHandler={(order: Order, status: string) =>
            changeOrderStatusHandler(order, status)
          }
          selectOrderHandler={(e: any, order: Order) =>
            dispatch(
              setSelectedOrders(
                e.target.checked
                  ? [...selectedOrders, order]
                  : selectedOrders.filter((o) => o !== order)
              )
            )
          }
          selectedOrders={selectedOrders}
          isPicker={isPicker}
          isHQTeam={isHQTeam}
          isAdmin={isAdmin}
          order={order}
          key={order.id}
        />
      ))}
    </MainTable>
  );
  //#endregion
}
