//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { fetchUsersAsync } from "./usersSlice";
import { useEffect } from "react";
import UsersContainer from "../../components/users/UsersContainer";
//#endregion

export default function UsersPage() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { users_loaded } = useAppSelector((state) => state.users);
  //#endregion

  //#region LOGIC
  // Load users
  useEffect(() => {
    const fetchData = async () => {
      if (!users_loaded) {
        await dispatch(fetchUsersAsync());
      }
    };
    fetchData();
  }, [users_loaded, dispatch]);
  //#endregion

  //#region RENDER
  return <UsersContainer />;
  //#endregion
}
