//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { StoreDetail } from "../../models/store";
import { closeModal } from "../dashBoard/dashboardSlice";
import { toast } from "react-toastify";
import StoreManageForm from "../../components/storeManage/StoreManageForm";
import useAdminManager from "../../hooks/useAdminManager";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import MainContainer from "../../components/_layout/MainContainer";
import GoBackButton from "../../components/genericButtons/goBackButton/GoBackButton";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import agent from "../../api/agent";
//#endregion

export default function StoreManage() {
  //#region SETUP
  // Router
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  const { manageMode } = useAppSelector((state) => state.stores);
  const { user } = useAppSelector((state) => state.account);

  // Local State
  const isAdmin = useAdminManager(user);
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState<StoreDetail>();
  //#endregion

  //#region LOGIC
  // Fetch ShopDetail
  useEffect(() => {
    const fetchStoreDetail = async () => {
      setLoading(true);
      try {
        const { data } = await agent.StoreManagement.detail(parseInt(id));
        setStore(data);
        setLoading(false);
      } catch (error) {
        toast.error(`${error}`);
        setLoading(false);
      }
    };
    id && fetchStoreDetail();
  }, [id]);

  // Close form
  const closeFormHandler = () => {
    history.push("/stores");
    dispatch(closeModal());
  };

  //#endregion

  if (loading) return <LoadingSpinner />;

  return (
    <MainContainer>
      <PageHeader title={store?.store_name || "detail"} isAdmin={isAdmin}>
        <GoBackButton />
      </PageHeader>

      <section className="store-page__content">
        <StoreManageForm
          store={store}
          manageMode={manageMode}
          closeHandler={closeFormHandler}
        />
      </section>
    </MainContainer>
  );
}
