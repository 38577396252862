//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { SortingStateOption } from "../../models/sorting";
import { setStoreParams } from "../../features/stores/storesSlice";
import { useTranslation } from "react-i18next";
import MainTableHeaderCell from "../_layout/MainTableHeaderCell";
//#endregion

export default function StoresTableHeader() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { filters_loaded } = useAppSelector((state) => state.stores);
  //#endregion

  //#region LOGIC

  // Set order sorting
  const sortChangedHandler = (option: SortingStateOption) => {
    if (option.column !== null) {
      dispatch(
        setStoreParams({
          sort: JSON.stringify(option),
        })
      );
    } else {
      dispatch(setStoreParams({ sort: undefined }));
    }
  };
  //#endregion

  return (
    <thead>
      <tr>
        {/* actions */}
        <th
          scope="col"
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs font-semibold text-gray-900"
        />
        {/* Store number */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.storeId")}
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          disabled={!filters_loaded}
          sortName="external_store_id"
        />

        {/* Store name */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          headerText={t("rituals.storeName")}
          disabled={!filters_loaded}
          sortName="store_name"
        />

        {/* Street name */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.streetName")}
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
          disabled={!filters_loaded}
          sortName="address_1"
        />

        {/* Postal code */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.postalCode")}
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
          disabled={!filters_loaded}
          sortName="postal_code"
        />

        {/* City */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.city")}
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
          disabled={!filters_loaded}
          sortName="city"
        />

        {/* Country */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.country")}
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
          disabled={!filters_loaded}
          sortName="country_name"
        />

        {/* Language */}
        <th
          scope="col"
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
        >
          {t("rituals.language")}
        </th>

        {/* Last updated */}
        <th
          scope="col"
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
        >
          {t("rituals.lastUpdated")}
        </th>

        {/* Openings data */}
        <th
          scope="col"
          className="whitespace-nowrap px-2 w-52 py-3.5 text-left text-xs font-semibold text-gray-900"
        >
          {t("rituals.openingsDate")}
        </th>

        {/* Store format */}
        <th
          scope="col"
          className="whitespace-nowrap px-2 w-52 py-3.5 text-left text-xs font-semibold text-gray-900"
        >
          {t("rituals.storeFormat")}
        </th>

        {/* Comments */}
        {/* <th
          scope="col"
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
        /> */}
      </tr>
    </thead>
  );
}
