//#region IMPORTS
import { MinusIcon, PlusIcon } from "@heroicons/react/16/solid";
import { Disclosure } from "@headlessui/react";
import { ReactNode } from "react";
import classNames from "../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  title: string;
  children: ReactNode;
}
//#endregion

export default function ProfileStoreHideAndClick({
  title,
  children,
}: Props) {
  //#region RENDER
  return (
    <div className="divide-y divide-gray-200 border-t">
      <Disclosure as="div">
        {({ open }) => (
          <>
            <h3>
              <Disclosure.Button className="group relative flex w-full items-center justify-between py-3 text-left">
                <span
                  className={classNames(
                    open ? "text-slate-900" : "text-gray-900",
                    "text-sm font-medium"
                  )}
                >
                  {title}
                </span>
                <span className="ml-6 flex items-center">
                  {open ? (
                    <MinusIcon
                      className="block h-5 w-5 text-slate-900 group-hover:text-slate-900"
                      aria-hidden="true"
                    />
                  ) : (
                    <PlusIcon
                      className="block h-5 w-5 text-amber-400 group-hover:text-amber-900"
                      aria-hidden="true"
                    />
                  )}
                </span>
              </Disclosure.Button>
            </h3>
            <Disclosure.Panel as="div" className="prose prose-sm pb-4">
              {children}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
  //#endregion
}
