//#region IMPORTS
import {
  clearOrders,
  clearOrderParams,
} from "../../features/orders/ordersSlice";
import { Fragment, useEffect, useState } from "react";
import { clearProductVersions } from "../../features/productVersions/productVersionsSlice";
import { TranslationLanguage } from "../../models/translations";
import { resetDashboard } from "../../features/dashBoard/dashboardSlice";
import { clearBackOrder } from "../../features/backorder/backOrderSlice";
import { useAppDispatch } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { clearProducts } from "../../features/products/productsSlice";
import { clearReasons } from "../../features/basketReason/basketReasonSlice";
import { clearPromos } from "../../features/promos/promosSlice";
import { clearBasket } from "../../features/basket/basketSlice";
import { clearStores } from "../../features/stores/storesSlice";
import { clearUsers } from "../../features/users/usersSlice";
import { useHistory } from "react-router-dom";
import { signOut } from "../../features/accounts/accountSlice";
import { AppUser } from "../../models/user";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import TranslationDropdown from "../translationDropdown/TranslationDropdown";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import ProfileAvatar from "./ProfileAvatar";
import SquareButton from "../genericButtons/squareButton/SquareButton";
import useHQmanager from "../../hooks/useHQmanager";
import ProfileStore from "./ProfileStore";
import agent from "../../api/agent";
import "./Profile.css";
//#endregion

//#region INTERFACE
interface Props {
  open: boolean;
  user: AppUser;
}
//#endregion

export default function Profile({ open, user }: Props) {
  //#region SETUP
  // Translation
  const { t, i18n } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();

  // Local state
  const [availableLanguages, setAvailableLanguages] = useState<
    TranslationLanguage[]
  >([]);

  // Roles
  const isAdmin = useAdminManager(user);
  const isPicker = usePickerManager(user);
  const isHqTeam = useHQmanager(user);

  const asideVariants = {
    open: { x: -320 },
    closed: { x: 0 },
  };
  //#endregion

  //#region LOGIC
  // Fetch TranslationLanguages
  useEffect(() => {
    const fetchTranslationLanguages = async () => {
      try {
        const response = await agent.Translations.getAvailableLanguages();
        setAvailableLanguages(response.languages);
      } catch (error) {
        toast.error(
          "Unable to fetch the available languages. Today it will be in English..."
        );
      }
    };
    fetchTranslationLanguages();
  }, []);

  // Remove all crap from everywhere
  const logoutHandler = () => {
    dispatch(signOut());
    dispatch(clearBackOrder());
    dispatch(clearBasket());
    dispatch(clearReasons());
    dispatch(clearPromos());
    dispatch(clearOrders());
    dispatch(clearStores());
    dispatch(clearUsers());
    dispatch(clearProducts());
    dispatch(clearProductVersions());
    dispatch(resetDashboard());
    i18n.changeLanguage("en");
  };

  // History referesh
  const historyHandler = () => {
    dispatch(clearOrderParams());
    history.push("/orders");
    dispatch(resetDashboard());
  };
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      <div
        className={
          open ? "profile-overlay" : "profile-overlay profile-overlay--hidden"
        }
        onClick={() => dispatch(resetDashboard())}
      />

      <motion.aside
        className="profile"
        animate={open ? "open" : "closed"}
        transition={{ type: "spring", mass: 0.5 }}
        variants={asideVariants}
      >
        <div className="profile__container">
          <header className="profile__container__header">
            <ProfileAvatar
              clickhandler={() => dispatch(resetDashboard())}
              user={user}
            />
          </header>
          <section className="profile__container__content">
            <SquareButton
              clickHandler={logoutHandler}
              value="logout"
              marginTop="0"
              marginBottom="1rem"
              color="#fbb033"
              overrideWidth="100%"
            />

            {!isPicker && !isAdmin && !isHqTeam ? (
              <SquareButton
                clickHandler={historyHandler}
                value={t("rituals.history")}
                marginBottom="1rem"
                className="history-hide"
              />
            ) : null}

            <header className="profile__container__content__header">
              <h2 className="profile__container__content__header__name serif">
                {user.name}
              </h2>
              <p className="profile__container__content__header__email">
                {user.email}
              </p>
            </header>
            <TranslationDropdown availableLanguages={availableLanguages} />

            {user.store && (
              <article className="profile__container__content__divider">
                <ProfileStore userStore={user.store} />
              </article>
            )}
          </section>
        </div>
      </motion.aside>
    </Fragment>
  );
  //#endregion
}
