//#region IMPORTS
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";
import { Popover, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import MainFilterBarFilteredList from "./MainFilterBarFilteredList";
import classNames from "../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  clearAllFiltersHandler?: () => void;
  clearFilterHandler: (paramName: string) => void;
  extensionButtons?: ReactNode;
  paramNamesList?: object;
  children: ReactNode;
  disabled?: boolean;
}
//#endregion

export default function MainFilterBar({
  clearAllFiltersHandler,
  clearFilterHandler,
  extensionButtons,
  paramNamesList,
  disabled = false,
  children,
}: Props) {
  //#region RENDER
  return (
    <Popover className="sticky top-52 z-40">
      <header className="bg-white h-12 w-full flex justify-between">
        <div className="flex gap-6 h-8">
          <Popover.Button
            disabled={disabled}
            className={classNames(
              disabled ? "opacity-50" : "opacity-100",
              "inline-flex items-center gap-x-1 text-lg font-semibold leading-6 text-green-950"
            )}
          >
            <AdjustmentsVerticalIcon className="h-7 w-7" aria-hidden="true" />
            Filters
          </Popover.Button>

          <MainFilterBarFilteredList
            clearAllFiltersHandler={clearAllFiltersHandler}
            clearFilterHandler={(name: string) => clearFilterHandler(name)}
            paramNamesList={paramNamesList}
          />
        </div>
        {extensionButtons}
      </header>

      <Transition
        as={Fragment}
        enter="transition ease-[cubic-bezier(0.22, 1, 0.36, 1)] duration-200"
        enterFrom="transform -translate-y-4 opacity-0"
        enterTo="transform translate-y-0 opacity-100"
        leave="transition ease-[cubic-bezier(0.22, 1, 0.36, 1)] duration-150"
        leaveFrom="transform translate-y-0 opacity-100"
        leaveTo="transform -translate-y-4 opacity-0"
      >
        <Popover.Panel className="absolute top-8 -z-50 bg-white pt-4 w-full rounded-b-xl">
          <div className="mx-auto grid max-w-full grid-cols-1 gap-2 px-6 py-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 sm:py-10 lg:grid-cols-4 lg:gap-4 lg:px-8 2xl:grid-cols-5 2xl:gap-8 bg-white shadow-xl rounded-b-xl">
            {children}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
  //#endregion
}
