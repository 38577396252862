//#region IMPORTS
import { ORDERSTATUS, ORDERTYPE, Order } from "../../models/order";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/configureStore";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import useHQmanager from "../../hooks/useHQmanager";
//#endregion

//#region INTERFACE
interface Props {
  selectOrderHandler: (e: any, value: Order) => void;
  selectedOrders: Order[];
  order: Order;
}
//#endregion

export default function OrdersTableRowCheckbox({
  selectOrderHandler,
  selectedOrders = [],
  order,
}: Props) {
  //#region SETUP
  // Redux
  const { order_params } = useAppSelector((state) => state.orders);

  // Roles
  const { user } = useAppSelector((state) => state.account);
  const isPicker = usePickerManager(user);
  const isHQTeam = useHQmanager(user);
  const isAdmin = useAdminManager(user);

  // LocalState
  const [showBox, setShowBox] = useState<boolean>(false);
  //#endregion

  //#region LOGIC
  useEffect(() => {
    if (isPicker || isAdmin)
      order.order_status !== ORDERSTATUS.cancelled && setShowBox(true);
    if (
      isHQTeam &&
      order.order_status === ORDERSTATUS.pending &&
      order.order_type !== ORDERTYPE.original &&
      order.order_status !== ORDERSTATUS.cancelled
    )
      setShowBox(true);
  }, [isAdmin, isHQTeam, isPicker, order, order_params]);
  //#endregion

  //#region RENDER
  return (isAdmin || isHQTeam || isPicker) && showBox ? (
    <input
      type="checkbox"
      className="h-4 w-4 rounded border-gray-300 text-fuchsia-600 focus:ring-fuchsia-100"
      value={order.id}
      checked={!!selectedOrders.find((so) => so.id === order.id)}
      onChange={(e: any) => selectOrderHandler(e, order)}
    />
  ) : null;
  //#endregion
}
