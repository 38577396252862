//#region IMPORTS
import {
  fetchProductVersionAsync,
  productVersionsSelectors,
  deleteProductVersionAsync,
} from "./productVersionsSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ProductVersionsUpdateForm from "../../components/productVersions/productVersionsForm/ProductVersionsUpdateForm";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import DeleteButton from "../../components/genericButtons/deleteButton/DeleteButton";
import GoBackButton from "../../components/genericButtons/goBackButton/GoBackButton";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import CancelIcon from "../../components/icons/CancelIcon";
import PencilIcon from "../../components/icons/PencilIcon";
import NotFound from "../../components/errors/NotFound";
//#endregion

export default function ProductVersionsDetailPage() {
  //#region SETUP
  // Router
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  // Redux
  const dispatch = useAppDispatch();
  const productversion = useAppSelector((state) =>
    productVersionsSelectors.selectById(state, id)
  );

  // Local state
  const [loading, setLoading] = useState<boolean>(true);
  const [updateMode, setUpdateMode] = useState(false);
  //#endregion

  //#region LOGIC
  useEffect(() => {
    const fetchProductVersion = async () => {
      if (!productversion)
        await dispatch(fetchProductVersionAsync(parseInt(id)));
      setLoading(false);
    };
    fetchProductVersion();
  }, [id, productversion, dispatch]);

  const deleteHandler = async () => {
    if (productversion) {
      await dispatch(deleteProductVersionAsync({ id: parseInt(id) }));
    }
  };
  //#endregion

  //#region FAIL SAFE
  if (loading) return <LoadingSpinner />;
  if (!productversion) return <NotFound />;
  //#endregion

  //#region RENDER
  return (
    productversion && (
      <article className="products-page">
        <PageHeader
          reset={() => history.push("/productversions")}
          title={updateMode ? "Edit Productversion" : "Productversion info"}
          extra={
            !updateMode && (
              <DeleteButton color="#f25c5c" clickHandler={deleteHandler} />
            )
          }
        >
          <GoBackButton />
        </PageHeader>

        <section className="products-page__content">
          {updateMode ? (
            <motion.button
              whileHover={{ scale: 1.1 }}
              className="products-page__content__new-box__cancel"
              onClick={() => setUpdateMode(false)}
            >
              <CancelIcon />
            </motion.button>
          ) : (
            <motion.button
              whileHover={{ scale: 1.1 }}
              className="products-page__content__new-box__cancel"
              onClick={() => setUpdateMode(true)}
            >
              <PencilIcon width={32} />
            </motion.button>
          )}

          <ProductVersionsUpdateForm
            productVersion={productversion}
            active={updateMode}
            cancelCUD={() => setUpdateMode(false)}
          />
        </section>
      </article>
    )
  );
  //#endregion
}
