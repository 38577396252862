//#region IMPORTS
import {
  TranslationLine,
  TranslationLanguage,
} from "../../models/translations";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import TranslationManagerListItem from "./TranslationManagerListItem";
import TranslationManagerForm from "./TranslationManagerForm";
//#endregion

//#region INTERFACE
interface Props {
  toggleCreateHandler: () => void;
  languages: TranslationLanguage[];
  create: boolean;
  colums: string[];
  items: TranslationLine[];
}
//#endregion

export default function TranslationManagerList({
  toggleCreateHandler,
  languages,
  create,
  colums,
  items,
}: Props) {
  //#region SETUP
  // Local state
  const [currentEditingId, setCurrentEditingId] = useState<number | null>(null);
  const [currentDirty, setCurrentDirty] = useState<boolean>(false);

  // Framermotion
  const animations = {
    initial: { scale: 1, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
    transition: { type: "spring", stiffness: 600, damping: 40, mass: 2 },
  };

  //#endregion

  //#region LOGIC
  // Find name by code
  function getNameByCode(code: string) {
    const language = languages.find((lang) => lang.code === code);
    return language ? language.name : null;
  }

  useEffect(() => {
    if (create) setCurrentDirty(false);
    setCurrentEditingId(null);
  }, [create]);

  //#endregion

  //#region RENDER
  return (
    <section className="translation-manager-list">
      <header className="translation-manager-list__header">
        <div
          className="translation-manager-list__header__cell"
          style={{ width: `${100 / colums.length}%` }}
        >
          Key
        </div>
        {colums.map((col, index) => (
          <div
            key={index}
            className="translation-manager-list__header__cell"
            style={{ width: `${100 / colums.length}%` }}
          >
            {getNameByCode(col) ?? col}
          </div>
        ))}
        <div className="translation-manager-list__list__item__cell--save">
          {currentEditingId && currentDirty && <p>Save</p>}
        </div>
      </header>
      <main className="translation-manager-list__list">
        <AnimatePresence>
          <motion.div
            {...animations}
            layout
            className="translation-manager-list__list__itemwrapper"
          >
            {create && (
              <TranslationManagerForm
                resetCreate={toggleCreateHandler}
                columsAmmount={colums.length}
              />
            )}
          </motion.div>
          {items.map((item, index) => (
            <motion.div
              key={index}
              {...animations}
              layout
              className="translation-manager-list__list__itemwrapper"
            >
              <TranslationManagerListItem
                key={index}
                setCurrentEditingId={(id) => setCurrentEditingId(id)}
                setCurrentDirty={(dirty) => setCurrentDirty(dirty)}
                currentEditingId={currentEditingId}
                columsAmmount={colums.length}
                disabled={currentDirty || create}
                item={item}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </main>
    </section>
  );
  //#endregion
}
