//#region IMPORTS
import { promosSelectors } from "../../features/promos/promosSlice";
import { useAppSelector } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { Basket } from "../../models/basket";
import ContinueButton from "../genericButtons/continueButton/ContinueButton";
//#endregion

//#region INTERFACE
interface Props {
  isPicker: boolean;
  isAdmin: boolean;
  HQTeam: boolean;
  basket: Basket | null;
}
//#endregion

export default function HomeFooter({
  isPicker,
  isAdmin,
  HQTeam,
  basket,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const currentPromo = useAppSelector(
    (state) => basket && promosSelectors.selectById(state, basket.promo_id)
  );
  //#endregion

  //#region RENDER
  return (
    <footer className="home__footer">
      {!HQTeam && !isAdmin && !isPicker ? (
        <div className="text-base text-slate-500">
          <p>
            {currentPromo
              ? t("rituals.myCurrentSelectedProjectIs")
              : t("rituals.noCurrentSelectedProject")}
            {currentPromo && currentPromo?.code}
          </p>
        </div>
      ) : (
        <div className="text-base text-slate-500">
          <p>powered by Albe De Coker</p>
        </div>
      )}

      {basket && (
        <ContinueButton
          color="#000000"
          value={t("rituals.basket")}
          to="/basket"
        />
      )}
    </footer>
  );
  //#endregion
}
