//#region IMPORTS
import { useHistory } from "react-router-dom";
import { Fragment } from "react";
import { EyeIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import { Order } from "../../models/order";
//#endregion

//#region INTERFACE
interface Props {
  order: Order;
}
//#endregion

export default function OrdersTableRowActionRead({ order }: Props) {
  //#region SETUP
  // Router
  const history = useHistory();
  //#endregion

  //#region LOGIC
  // Redirect to detail page
  const detailHandler = () => {
    history.push(`/orders/${order.id}`, true);
  };
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      <button
        data-tooltip-content="Show order detail"
        data-tooltip-variant="dark"
        data-tooltip-place="right"
        data-tooltip-id={String(order.id) + "read"}
        data-tooltip-delay-show={500}
        className="flex items-center gap-4"
      >
        <EyeIcon
          className="w-5 h-5 text-emerald-500 hover:text-emerald-400"
          onClick={detailHandler}
          type="button"
        />
      </button>
      <Tooltip id={String(order.id) + "read"} />
    </Fragment>
  );
  //#endregion
}
