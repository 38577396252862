//#region IMPORTS
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { toast } from "react-toastify";
import FeaturePageEN from "./FeaturePageLanguageVersions/FeaturePageEN";
import FeaturePageNL from "./FeaturePageLanguageVersions/FeaturePageNL";
import MainContainer from "../../components/_layout/MainContainer";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import "./FeaturePage.css";
//#endregion

export default function FeaturePage() {
  //#region SETUP
  // Translation
  const { t, i18n } = useTranslation();
  //#endregion

  //#region LOGIC
  useEffect(() => {
    toast.info(
      t("rituals.pleaseContactYourRetailCoordinatorIfYouHaveAnyQuestions"),
      { autoClose: 5000 }
    );
  }, [t]);

  const renderLangueageVersion = () => {
    switch (i18n.language.toString()) {
      case "en":
        return <FeaturePageEN />;
      case "nl":
        return <FeaturePageNL />;
      default:
        return <FeaturePageEN />;
    }
  };
  //#endregion

  //#region RENDER
  return (
    <MainContainer>
      <section id="lognav">
        <PageHeader title={t("rituals.manual")} />
        {renderLangueageVersion()}
      </section>
    </MainContainer>
  );
  //#endregion
}
