import { useTranslation } from "react-i18next";
import { MetaData } from "../../models/pagination";
import PaginationBarItem from "./PaginationBarItem";
import "./PaginationBar.css";

interface Props {
  onPageChange: (page: number) => void;
  metaData?: MetaData;
  pageType: string;
  loaded?: boolean;
  hide?: boolean;
}

export default function PaginationBar({
  onPageChange,
  metaData,
  pageType,
  loaded = true,
  hide = false,
}: Props) {
  // Translation
  const { t } = useTranslation();

  return !hide && metaData && metaData.total > 0 ? (
    <footer>
      <div className="pagination-bar">
        <div className="pagination-bar__group pagination-bar__group--left">
          {metaData.last_page > 1 && (
            <div className="pagination-bar__group__pagecounter">
              <p
                className={`pagination-bar__group__pagecounter__current${
                  !loaded
                    ? " pagination-bar__group__pagecounter__current--loading"
                    : ""
                }`}
              >
                {metaData.current_page}
              </p>
              <div className="pagination-bar__group__pagecounter__divider" />
              <p className="pagination-bar__group__pagecounter__total">
                {metaData.last_page}
              </p>
            </div>
          )}
        </div>

        {
          <div className="pagination-bar__group pagination-bar__group--pagination">
            {metaData.links.map((link, index) => (
              <PaginationBarItem
                currentPage={metaData.current_page}
                lastPage={metaData.last_page}
                link={link}
                key={index}
                onPageChange={(pageNr) => onPageChange(pageNr)}
              />
            ))}
          </div>
        }

        <p className="pagination-bar__group pagination-bar__group--right">
          <b>
            {metaData.from} - {metaData.to}
          </b>{" "}
          {t("rituals.of")}{" "}
          <b>
            {metaData.total} {pageType}
          </b>
        </p>
      </div>
    </footer>
  ) : null;
}
