//#region IMPORTS
import { Fragment } from "react";
import { Tooltip } from "react-tooltip";
//#endregion

//#region INTERFACE
interface Props {
  clearFilterHandler: (name: string) => void;
  value: string;
  hide?: boolean;
  name: string;
}
//#endregion

export default function MainFilterBarFilteredListItem({
  clearFilterHandler,
  value,
  hide = false,
  name,
}: Props) {
  //#region RENDER
  return !hide ? (
    <Fragment>
      <span
        data-tooltip-id={value + name + "3c71e2b7-368c-45f0-b1b5-6db340954ea7"}
        data-tooltip-content={name.split("_").join(" ")}
        key={name}
        className="inline-flex items-center gap-x-0.5 rounded-md bg-slate-100 px-2 py-1 text-xs font-medium text-slate-600 ring-1 ring-inset ring-slate-500/20"
      >
        {value === "false"
          ? "non-active only"
          : value === "true"
          ? "active only"
          : value}
        <button
          type="button"
          onClick={() => clearFilterHandler(name)}
          className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
        >
          <svg
            viewBox="0 0 14 14"
            className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
          >
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span className="absolute -inset-1" />
        </button>
      </span>
      <Tooltip id={value + name + "3c71e2b7-368c-45f0-b1b5-6db340954ea7"} />
    </Fragment>
  ) : null;
  //#endregion
}
