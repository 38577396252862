//#region IMPORTS
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon as DarkXCircleIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { Tooltip } from "react-tooltip";
import classNames from "../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  filterParameterName: string;
  displayExplanation?: string;
  clearFilterAction: () => void;
  placeholderText?: string;
  selectBoxItems?: string[];
  selectedValue?: string;
  filterAction: (value: any) => void;
  displayText?: string;
  selectBox?: boolean;
  selected?: boolean;
  disabled?: boolean;
  type?: "text" | "date";
  hide?: boolean;
}
//#endregion

export default function MainFilterBarItem({
  filterParameterName,
  displayExplanation = "This is a filter.",
  clearFilterAction,
  placeholderText = "enter input here...",
  selectBoxItems = [],
  selectedValue = "",
  filterAction,
  displayText = "filteritem",
  selectBox = false,
  selected = true,
  disabled = false,
  type = "text",
  hide = false,
}: Props) {
  //#region SETUP
  const [value, setValue] = useState<string>(selectedValue);
  const noSelectBoxItems: boolean = selectBox && selectBoxItems.length <= 0;
  //#endregion

  //#region LOGIC
  const filterActionHandler = (filterValue: string) => {
    filterValue.trim() && filterAction(filterValue);
  };
  //#endregion

  //#region RENDER
  return !hide ? (
    <div
      className={classNames(
        noSelectBoxItems || disabled ? "opacity-30" : "opacity-100",
        !selected && selectBox && selectBoxItems.length <= 1
          ? ""
          : "hover:bg-gray-100",
        "group relative -mx-3 flex flex-col justify-between rounded-lg p-3 leading-6 "
      )}
    >
      <div className="w-full flex gap-4">
        <button
          className="flex items-start pt-0.5"
          data-tooltip-id={filterParameterName}
          data-tooltip-content="Clear filter"
          disabled={!selected || disabled}
          onClick={() => {
            clearFilterAction();
            setValue("");
          }}
        >
          {selected ? (
            <DarkXCircleIcon className="h5 w-5 text-red-500" />
          ) : (
            <XCircleIcon className="h5 w-5 text-gray-200" />
          )}
          {selected && <Tooltip id={filterParameterName} />}
        </button>

        <div
          className={classNames(
            !selected && selectBox && selectBoxItems.length <= 1
              ? "opacity-30"
              : "opacity-100"
          )}
        >
          <p className="text-slate-700 text-base font-medium">{displayText}</p>
          <p className="mt-1 text-gray-400 text-xs">{displayExplanation}</p>
        </div>
      </div>

      {selectBox ? (
        <Fragment>
          <div className="pl-8">
            <select
              id={filterParameterName}
              name={displayText}
              value={value}
              disabled={
                disabled ||
                noSelectBoxItems ||
                selected ||
                (!selected && selectBoxItems.length <= 1)
              }
              className={classNames(
                noSelectBoxItems ||
                  selected ||
                  (!selected && selectBoxItems.length <= 1)
                  ? "opacity-40"
                  : "opacity-100",
                !value ? "text-gray-400" : "text-gray-900",
                "w-full mt-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10  ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-slate-600 sm:text-sm sm:leading-6"
              )}
              onChange={(e: any) => {
                setValue(e.target.value);
                filterActionHandler(e.target.value);
              }}
            >
              <option
                disabled={!!value || (!selected && selectBoxItems.length <= 1)}
                className="opacity-30"
                key={0}
              >
                select a {displayText}
              </option>
              {selectBoxItems.map((item: string, index: number) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </Fragment>
      ) : (
        <form
          className="mt-4 w-full flex gap-4"
          onSubmit={(e: any) => {
            e.preventDefault();
            filterActionHandler(value);
          }}
        >
          <button
            className="h-full w-5 text-gray-400"
            type="submit"
            disabled={!value || disabled}
          >
            <MagnifyingGlassIcon aria-hidden="true" />
          </button>
          <input
            id={filterParameterName}
            name={displayText}
            disabled={disabled}
            value={value}
            className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
            placeholder={placeholderText}
            type={type}
            onChange={(e: any) => setValue(e.target.value.trimStart())}
          />
        </form>
      )}
    </div>
  ) : null;
  //#endregion
}
