//#region IMPORTS
import {
  fetchFilters,
  deleteProductAsync,
} from "../../features/products/productsSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useState, useEffect, Fragment } from "react";
import { DELETE_PRODUCT_SURE } from "../../models/_consts";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { openModal } from "../../features/dashBoard/dashboardSlice";
import { Product } from "../../models/product";
import { motion } from "framer-motion";
import ProductsFilterBar from "./ProductsFilterBar";
import useAdminManager from "../../hooks/useAdminManager";
import ProductsTable from "./ProductsTable";
import MainContainer from "../_layout/MainContainer";
import ProductsForm from "./productsForm/ProductsForm";
import PageHeader from "../genericPageHeader/PageHeader";
import AreYouSure from "../modalTypes/AreYouSure";
import CancelIcon from "../icons/CancelIcon";
import MainModal from "../_layout/MainModal";
//#endregion

export default function ProductsContainer() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux state
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);
  const { products_loaded, filters_loaded, metaData } = useAppSelector(
    (state) => state.products
  );

  // Roles
  const isAdmin = useAdminManager(user);

  // Local state
  const [create, setCreate] = useState<boolean>(false);
  const [productToManage, setProductToManage] = useState<Product>();
  //#endregion

  //#region LOGIC
  // Load filters
  useEffect(() => {
    const fetchFiltering = async () => {
      await dispatch(fetchFilters());
    };
    !filters_loaded && fetchFiltering();
  }, [filters_loaded, dispatch]);

  // Create new product
  const toggleCreate = () => {
    setCreate((prev) => !prev);
  };

  // Redirect to detail page
  const detailHandler = (id: number) => {
    history.push(`products/${id}`, true);
  };

  // Set delete warning
  const deleteWarningHandler = async (product: Product) => {
    setProductToManage(product);
    dispatch(openModal(DELETE_PRODUCT_SURE));
  };

  // Confirm delete
  const deleteHandler = async () => {
    productToManage &&
      (await dispatch(deleteProductAsync({ id: productToManage.id })));
    cancelCUD();
  };

  // Cancel CUD product
  const cancelCUD = () => {
    dispatch(openModal(false));
    setProductToManage(undefined);
  };
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* MODALS */}
      <MainModal type={DELETE_PRODUCT_SURE} closeHandler={cancelCUD}>
        <AreYouSure
          confirmHandler={deleteHandler}
          closeHandler={cancelCUD}
          message={`Are you sure you want to delete product ${productToManage?.name}`}
          title="Delete product?"
        />
      </MainModal>

      {/* BODY */}
      <MainContainer>
        <PageHeader
          createClickHandler={toggleCreate}
          createButtonValue={create ? t("rituals.save") : t("rituals.add")}
          isAdmin={isAdmin}
          create={create}
          title={t("rituals.products")}
        />

        <ProductsFilterBar />

        {/* Create from form*/}
        {isAdmin &&
          (create ? (
            <div className="products-page__content__new-box">
              <motion.button
                whileHover={{ scale: 1.1, rotate: "90deg" }}
                className="products-page__content__new-box__cancel"
                onClick={toggleCreate}
              >
                <CancelIcon />
              </motion.button>
              <ProductsForm cancelCUD={toggleCreate} />
            </div>
          ) : (
            <ProductsTable
              deleteHandler={(product: Product) =>
                deleteWarningHandler(product)
              }
              detailHandler={(product: Product) => detailHandler(product.id)}
              itemsLoaded={products_loaded}
              metaData={metaData}
              hide={!products_loaded && !filters_loaded}
            />
          ))}
      </MainContainer>
    </Fragment>
  );
  //#endregion
}
