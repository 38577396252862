//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { toggleProfile } from "../../features/dashBoard/dashboardSlice";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import DashboardHeaderBarAccount from "./DashboardHeaderBarAccount";
import RitualsLogo from "../logos/RitualsLogo";
import Profile from "../profile/Profile";
//#endregion

export default function DashboardHeaderBar() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.account);
  const { profileOpen } = useAppSelector((state) => state.dashboard);
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      <header className="sticky top-0 z-40 flex h-24 shrink-0 bg-white items-center gap-x-4 px-8 sm:px-6 lg:px-16">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => dispatch(toggleProfile())}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        <div className="flex flex-1 gap-x-4 items-center justify-between lg:gap-x-6">
          <NavLink to="/">
            <RitualsLogo className="w-44" />
          </NavLink>

          <DashboardHeaderBarAccount
            avatarClickHandler={() => dispatch(toggleProfile())}
            user={user}
          />
        </div>
      </header>

      {/* Profile sidedrawer  */}
      {user && <Profile open={profileOpen} user={user} />}
    </Fragment>
  );
  //#endregion
}
