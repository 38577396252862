//#region IMPORTS
import { updateApproveStateOfOrder } from "../../features/orders/ordersSlice";
import { useAppDispatch } from "../../store/configureStore";
import { useState } from "react";
import { Switch } from "@headlessui/react";
import { Order } from "../../models/order";
import classNames from "../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  clearSelectedOrdersHandler: () => void;
  order: Order;
  hide?: boolean;
}
//#endregion

export default function OrdersTableRowActionApproveToggle({
  clearSelectedOrdersHandler,
  order,
  hide = false,
}: Props) {
  //#region SETUP

  // Redux
  const dispatch = useAppDispatch();

  // Local state
  const [enabled, setEnabled] = useState(order.approved);
  //#endregion

  //#region LOGIC
  // Toggle approve or decline in batch
  const toggleItemInBatchHandler = () => {
    setEnabled((prev) => !prev);
    const orderToUpdate = { ...order };
    orderToUpdate.approved = !order.approved;
    dispatch(updateApproveStateOfOrder(orderToUpdate));
    clearSelectedOrdersHandler();
  };
  //#endregion

  //#region RENDER
  return !hide ? (
    <Switch
      onChange={() => toggleItemInBatchHandler()}
      className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
    >
      <span className="pointer-events-none absolute size-full rounded-md bg-pink-500" />
      <span className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out" />
      <span
        className={classNames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
        )}
      />
    </Switch>
  ) : null;
  //#endregion
}
