//#region IMPORTS
import { promosSelectors } from "../../features/promos/promosSlice";
import { useAppSelector } from "../../store/configureStore";
import { Fragment } from "react";
import { Basket } from "../../models/basket";
import { Promo } from "../../models/promo";
import HomeProjectItem from "./HomeProjectItem";
//#endregion

//#region INTERFACE
interface Props {
  isPicker: boolean;
  isAdmin: boolean;
  HQTeam: boolean;
  basket: Basket | null;
}
//#endregion

export default function HomeProjectItems({
  isPicker,
  isAdmin,
  HQTeam,
  basket,
}: Props) {
  //#region SETUP
  const promos = useAppSelector(promosSelectors.selectAll);
  const { promosLoaded } = useAppSelector((state) => state.promos);

  //#region RENDER
  return (
    <div className="home-project-items grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-4 lg:gap-4">
      {promosLoaded ? (
        promos
          .slice(0, 3)
          .map((project: Promo, index) => (
            <HomeProjectItem
              latestItem={index === 0}
              isPicker={isPicker}
              isAdmin={isAdmin}
              project={project}
              HQTeam={HQTeam}
              basket={basket}
              key={project.id}
            />
          ))
      ) : (
        // Loading items
        <Fragment>
          <HomeProjectItem
            latestItem
            isPicker={isPicker}
            isAdmin={isAdmin}
            HQTeam={HQTeam}
            basket={basket}
            key={1}
          />
          <HomeProjectItem
            isPicker={isPicker}
            isAdmin={isAdmin}
            HQTeam={HQTeam}
            basket={basket}
            key={2}
          />
          <HomeProjectItem
            isPicker={isPicker}
            isAdmin={isAdmin}
            HQTeam={HQTeam}
            basket={basket}
            key={3}
          />
        </Fragment>
      )}
    </div>
  );
  //#endregion
}
