//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { SortingStateOption } from "../../models/sorting";
import { setProductParams } from "../../features/products/productsSlice";
import { useTranslation } from "react-i18next";
import MainTableHeaderCell from "../_layout/MainTableHeaderCell";
//#endregion

export default function ProductsTableHeader() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { product_params } = useAppSelector((state) => state.products);
  //#endregion

  //#region LOGIC

  // Set order sorting
  const sortChangedHandler = (option: SortingStateOption) => {
    if (option.column !== null) {
      dispatch(
        setProductParams({
          sort: JSON.stringify(option),
        })
      );
    } else {
      dispatch(setProductParams({ sort: undefined }));
    }
  };
  //#endregion

  return (
    <thead>
      <tr>
        {/* read */}
        <th
          scope="col"
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs font-semibold text-gray-900"
        />
        {/* number */}
        <MainTableHeaderCell
          headerText={t("rituals.code")}
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
        />

        {/* name */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          headerText={t("rituals.name")}
          sortName="name"
          params={product_params}
        />

        {/* length */}
        <MainTableHeaderCell
          headerText={t("rituals.length")}
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
        />

        {/* width */}
        <MainTableHeaderCell
          headerText={t("rituals.width")}
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
        />

        {/* specs_material */}
        <MainTableHeaderCell
          headerText={t("rituals.material")}
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
        />

        {/* specs_sided */}
        <MainTableHeaderCell
          headerText={t("rituals.sided")}
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
        />

        {/* Delete */}
        <th
          scope="col"
          className="whitespace-nowrap px-2 w-16 py-3.5 text-left text-xs font-semibold text-gray-900"
        />
      </tr>
    </thead>
  );
}
