//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { fetchFilters, storeSelectors} from "../../features/stores/storesSlice";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { toast } from "react-toastify";
import useAdminManager from "../../hooks/useAdminManager";
import StoresFilterBar from "./StoresFilterBar";
import MainContainer from "../_layout/MainContainer";
import StoresTable from "./StoresTable";
import PageHeader from "../genericPageHeader/PageHeader";
import agent from "../../api/agent";
//#endregion

export default function StoresContainer() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const stores = useAppSelector(storeSelectors.selectAll);
  const { user } = useAppSelector((state) => state.account);
  const { stores_loaded, metaData, filters_loaded } = useAppSelector(
    (state) => state.stores
  );

  // Local State
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region LOGIC
  // Load filters
  useEffect(() => {
    const fetchFiltering = async () => {
      await dispatch(fetchFilters());
    };
    !filters_loaded && fetchFiltering();
  }, [filters_loaded, dispatch]);

  // Download all stores in a spreadsheet
  const downloadStoresSpreadsheet = async () => {
    try {
      await agent.StoreManagement.downloadAllAsSpreadsheet().then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "current-stores.xlsx");
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      );
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  //#endregion

  //#region RENDER
  return (
    <MainContainer>
      <PageHeader
        downloadClickHandler={downloadStoresSpreadsheet}
        download={true}
        isAdmin={isAdmin}
        title={t("rituals.storeManager")}
      />

      <StoresFilterBar />

      <StoresTable
        itemsLoaded={stores_loaded}
        metaData={metaData}
        items={stores}
        hide={!stores_loaded && !filters_loaded}
      />
    </MainContainer>
  );
  //#endregion
}
