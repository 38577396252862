//#region IMPORTS
import { Fragment, ReactNode } from "react";
import { useAppSelector } from "../../store/configureStore";
import DashboardHeaderBar from "./DashboardHeaderBar";
import DashboardSidebar from "./DashboardSidebar";
import classNames from "../../helpers/classNames";
//#endregion

//#region INTERFACE
interface Props {
  children: ReactNode;
}
//#endregion

export default function DashboardContainer({ children }: Props) {
  const { dashOpen } = useAppSelector((state) => state.dashboard);
  //#region RENDER
  return (
    <Fragment>
      {/* Sidebar for desktop */}
      <DashboardSidebar dashOpen={dashOpen} />

      {/* Dashboard body */}
      <section className={classNames(dashOpen ? "lg:pl-64" : "lg:pl-32")}>
        {/* Headerbar */}
        <DashboardHeaderBar />
        <main className="py-6 px-4 sm:px-6 lg:px-8">{children}</main>
      </section>
    </Fragment>
  );
  //#endregion
}
