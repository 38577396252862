import "./Loading.css";

interface Props {
  message?: string;
}

function LoadingData({ message }: Props) {
  return (
    <section>
      <div className="loading">
        <div className="l1">
          <div></div>
        </div>
        <div className="l2">
          <div></div>
        </div>
        <div className="l3">
          <div></div>
        </div>
        <div className="l4">
          <div></div>
        </div>
      </div>
      <h2 className="loading-spinner__message">{message}</h2>
    </section>
  );
}

export default LoadingData;
