//#region IMPORTS
import { SortingStateOption } from "../../models/sorting";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { setUserParams } from "../../features/users/usersSlice";
import MainTableHeaderCell from "../_layout/MainTableHeaderCell";
//#endregion

export default function UsersTableHeader() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { user_params } = useAppSelector((state) => state.users);
  //#endregion

  //#region LOGIC

  // Set order sorting
  const sortChangedHandler = (option: SortingStateOption) => {
    if (option.column !== null) {
      dispatch(
        setUserParams({
          sort: JSON.stringify(option),
        })
      );
    } else {
      dispatch(setUserParams({ sort: undefined }));
    }
  };
  //#endregion

  return (
    <thead>
      <tr>
        {/* Read */}
        <th
          scope="col"
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs font-semibold text-gray-900"
        />
        {/* username */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          headerText={t("rituals.name")}
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          sortName="name"
          params={user_params}
        />

        {/* email */}
        <MainTableHeaderCell
          sortChangedHandler={(option: SortingStateOption) =>
            sortChangedHandler(option)
          }
          className="whitespace-nowrap pr-4 py-3.5 text-left text-xs text-gray-900"
          headerText={t("rituals.email")}
          sortName="email"
          params={user_params}
        />

        {/* function */}
        <MainTableHeaderCell
          headerText={t("rituals.function")}
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
        />

        {/* external_store_id */}
        <MainTableHeaderCell
          headerText={t("rituals.storeId")}
          className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900"
        />

        {/* Delete */}
        <th
          scope="col"
          className="whitespace-nowrap px-2 w-16 py-3.5 text-left text-xs font-semibold text-gray-900"
        />
      </tr>
    </thead>
  );
}
