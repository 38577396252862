//#region IMPORTS
import { useAppSelector } from "../../store/configureStore";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import DashboardSidebarListItemText from "./DashboardSidebarListItemText";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import useHQmanager from "../../hooks/useHQmanager";
//#endregion

//#region INTERFACE
interface Props {
  clickhandler?: () => void;
  headquarters?: boolean;
  countItem?: number;
  dashOpen?: boolean;
  selected?: boolean;
  linkName: string;
  children: any;
  picker?: boolean;
  admin?: boolean;
  shop?: boolean;
  to?: string;
  //#endregion
}

export default function DashboardSidebarListItem({
  clickhandler,
  headquarters = true,
  countItem,
  dashOpen,
  selected,
  linkName,
  children,
  picker = true,
  admin = true,
  shop = true,
  to,
}: Props) {
  //#region SETUP
  // Redux
  const { user } = useAppSelector((state) => state.account);

  // Hooks
  const hqTeam = useHQmanager(user);
  const isAdmin = useAdminManager(user);
  const isPicker = usePickerManager(user);
  //#endregion

  //#region RENDER HELPER
  const render = () => (
    <li
      className="relative mt-4 h-8"
      onClick={clickhandler}
    >
      {to ? (
        <Link
          to={to}
          className="relative gap-4 flex items-center"
        >
          {children}
          {countItem !== undefined
            ? countItem > 0 && (
                <div className="absolute top-1 left-6 w-5 h-5 bg-red-200 text-xs flex items-center justify-center rounded-full border-1 border-red-500 text-red-500">
                  {countItem < 100 ? countItem : "!"}
                </div>
              )
            : null}
          <DashboardSidebarListItemText
            selected={selected}
            linkName={linkName}
            dashOpen={dashOpen}
          />
        </Link>
      ) : (
        <Fragment>
          {children}
          <DashboardSidebarListItemText
            selected={selected}
            linkName={linkName}
            dashOpen={dashOpen}
          />
        </Fragment>
      )}
    </li>
  );
  //#endregion

  //#region CONDITIONAL RENDERING
  if (!admin && isAdmin) {
    return null;
  } else if (!headquarters && hqTeam) {
    return null;
  } else if (!picker && isPicker) {
    return null;
  } else if (!hqTeam && !shop && !isAdmin && !shop && !isPicker && !shop) {
    return null;
  } else {
    return render();
  }
  //#endregion
}
