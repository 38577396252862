//#region IMPORTS
import { Squares2X2Icon as DarkSquares2X2Icon } from "@heroicons/react/20/solid";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import { Icon } from "../../models/icon";
//#endregion

export default function ProductIcon({
  selected = false,
  color = "#000000",
  selectedColor = "#3e5902",
  width = 24,
}: Icon) {
  //#region RENDER
  return selected ? (
    <DarkSquares2X2Icon style={{ width: width + "px", color: selectedColor }} />
  ) : (
    <Squares2X2Icon style={{ width: width + "px", color: color }} />
  );
  //#endregion
}
