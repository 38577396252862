//#region IMPORTS
import { useAppSelector } from "../../store/configureStore";
import { Fragment } from "react";
import OrdersTableControlePanelBulkActions from "./OrdersTableControlePanelBulkActions";
import OrdersTableControlePanelViewActions from "./OrdersTableControlePanelViewActions";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import useHQmanager from "../../hooks/useHQmanager";
//#endregion

export default function OrdersTableControlePanel() {
  //#region SETUP
  // Roles
  const { user } = useAppSelector((state) => state.account);
  const isPicker = usePickerManager(user);
  const isHQTeam = useHQmanager(user);
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      <div className="flex h-12 items-center justify-between gap-8 space-x-3 sm:left-12">
        {/* Bulk actions */}
        <OrdersTableControlePanelBulkActions
          isPicker={isPicker}
          isHQTeam={isHQTeam}
          isAdmin={isAdmin}
        />

        {/* View actions */}
        <div className="flex items-center">
          <OrdersTableControlePanelViewActions />
        </div>
      </div>
    </Fragment>
  );
  //#endregion
}
