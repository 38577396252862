//#region INTERFACE
interface Props {
  message?: string;
}
//#endregion

export default function EmptyList({ message = "This list is empty" }: Props) {
  //#region RENDER
  return <h1 className="font-bold text-7xl">{message}</h1>;
  //#endregion
}