//#region IMPORT
import { useAppDispatch } from "../../store/configureStore";
import { toggleDash } from "../../features/dashBoard/dashboardSlice";
import DashboardSidebarToggle from "./DashboardSidebarToggle";
import DashboardSidebarList from "./DashboardSidebarList";
import classNames from "../../helpers/classNames";
//#endregion

interface Props {
  dashOpen: boolean;
}

export default function DashboardSidebar({ dashOpen }: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  //#endregion

  //#region RENDER
  return (
    <div
      className={classNames(
        dashOpen ? "lg:w-64" : "lg:w-32",
        "hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex  lg:flex-col pt-4 pl-4 pb-4"
      )}
    >
      <div className="flex grow flex-col overflow-y-auto  p-4 bg-white border-r-2 border-gray-100">
        <DashboardSidebarToggle
          isOpen={dashOpen}
          onClick={() => dispatch(toggleDash())}
          strokeWidth="4"
          color="#000000"
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
          width="32"
          height="12"
        />

        {/* Navigation list */}
        <DashboardSidebarList dashOpen={dashOpen} />
      </div>
    </div>
  );
  //#endregion
}
