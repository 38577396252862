//#region IMPORTS
import {
  fetchTranslationLinesAsync,
  translationManagerSelectors,
  setTranslationManagerPageNumber,
} from "./translatioManagerSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { AnimatePresence, motion } from "framer-motion";
import { TranslationLanguage } from "../../models/translations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import TranslationManagerExcelLoader from "../../components/translationManager/TranslationManagerExcelLoader/TranslationManagerExcelLoader";
import TranslationManagerFilterBar from "../../components/translationManager/TranslationManagerFilterBar";
import TranslationManagerList from "../../components/translationManager/TranslationManagerList";
import useAdminManager from "../../hooks/useAdminManager";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import PaginationBar from "../../components/paginationBar/PaginationBar";
import MainContainer from "../../components/_layout/MainContainer";
import CancelIcon from "../../components/icons/CancelIcon";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import agent from "../../api/agent";
import "./TranslationManagerPage.css";
//#endregion

export default function TranslationManagerPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);
  const { translationsLoaded, metaData, languageColums } = useAppSelector(
    (state) => state.translationManagement
  );
  const items = useAppSelector(translationManagerSelectors.selectAll);

  // Local State
  const [languagesLoaded, setLanguagesLoaded] = useState<boolean>(false);
  const [uploadMode, setUploadMode] = useState<boolean>(false);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const [languages, setLanguages] = useState<TranslationLanguage[]>([]);
  const [create, setCreate] = useState<boolean>(false);
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region LOGIC
  // Load translations
  useEffect(() => {
    if (!translationsLoaded) dispatch(fetchTranslationLinesAsync());
  }, [translationsLoaded, dispatch]);

  // Fetch Languages localy
  useEffect(() => {
    const fetchAllTranslationManagerLanguageCodes = async () => {
      try {
        const { languages } = await agent.Translations.getAvailableLanguages();
        setLanguages(languages);
        setLanguagesLoaded(true);
      } catch (error) {
        setLanguagesLoaded(false);
        toast.error(`${error}`);
      }
    };
    fetchAllTranslationManagerLanguageCodes();
  }, []);

  // Create translation line
  const toggleCreateTranslationLine = () => {
    if (uploadMode) {
      setUploadMode(false);
    }
    setCreate((prev) => !prev);
  };

  // Upload translation lines
  const toggleUploadTranslationLines = () => {
    if (create) {
      setCreate(false);
    }
    setUploadMode((prev) => !prev);
  };
  //#endregion

  //#region RENDER
  return (
    <MainContainer>
      <PageHeader
        title={t("rituals.translations")}
        createButtonValue={create ? t("rituals.save") : t("rituals.add")}
        createClickHandler={toggleCreateTranslationLine}
        uploadButtonValue={
          uploadMode ? t("rituals.cancel") : t("rituals.uploadExcel")
        }
        uploadClickHandler={toggleUploadTranslationLines}
        create={create}
        isAdmin={isAdmin}
        upload={uploadMode}
        removeAllbuttonsWhenCRUD={create || uploadMode}
      >
        {/* CancelButtons */}
        {create ? (
          <motion.button
            whileHover={{ scale: 1.1, rotate: "90deg" }}
            className="translation-manager-list__header__cancel-box"
            onClick={toggleCreateTranslationLine}
          >
            <CancelIcon />
          </motion.button>
        ) : uploadMode ? (
          <motion.button
            whileHover={{ scale: 1.1, rotate: "90deg" }}
            className="translation-manager-list__header__cancel-box"
            onClick={toggleUploadTranslationLines}
          >
            <CancelIcon />
          </motion.button>
        ) : null}
      </PageHeader>

      <TranslationManagerFilterBar />

      {/* Excelloader */}
      <AnimatePresence>
        {uploadMode && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "50px" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <TranslationManagerExcelLoader
              setDownloaded={(value: boolean) => setDownloaded(value)}
              downloaded={downloaded}
              closeModal={toggleUploadTranslationLines}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <div>
        {metaData && translationsLoaded && languagesLoaded ? (
          <TranslationManagerList
            toggleCreateHandler={toggleCreateTranslationLine}
            languages={languages}
            colums={languageColums}
            create={create}
            items={items}
          />
        ) : (
          <LoadingSpinner />
        )}
      </div>
      <footer>
        {metaData && metaData?.total > 0 && (
          <PaginationBar
            metaData={metaData}
            onPageChange={(page: number) =>
              dispatch(setTranslationManagerPageNumber({ page: page }))
            }
            pageType={t("rituals.translations")}
          />
        )}
      </footer>
    </MainContainer>
  );
  //#endregion
}
