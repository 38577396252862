//#region IMPORTS
import { storeFormatSelectors } from "../../features/storeFormats/storeFormatSlice";
import { useAppSelector } from "../../store/configureStore";
import { Fragment } from "react";
import { Store } from "../../models/store";
import StoresTableRowActionReadUpdate from "./StoresTableRowActionReadUpdate";
import dateHelperWeirdFromBackend from "../../helpers/dateHelperWeirdFromBackend";
//#endregion

//#region INTERFACE
interface Props {
  isHQTeam: boolean;
  isPicker: boolean;
  isAdmin: boolean;
  store: Store;
}
//#endregion

export default function StoresTableRow({
  isHQTeam = false,
  isPicker = false,
  isAdmin = false,
  store,
}: Props) {
  const storeFormatsToSelect = useAppSelector(storeFormatSelectors.selectAll);

  //#region RENDER
  return (
    storeFormatsToSelect && (
      <Fragment>
        <tr className="border-t border-slate-300 h-10">
          {/* Actions */}
          <td className="whitespace-nowrap px-2 text-left text-xs text-gray-500 sm:pl-0">
            <StoresTableRowActionReadUpdate
              isHQTeam={isHQTeam}
              isPicker={isPicker}
              isAdmin={isAdmin}
              store={store}
            />
          </td>
          {/* Store number */}
          <td className="whitespace-nowrap px-2 text-left text-xs text-gray-500 sm:pl-0">
            {store.external_store_id}
          </td>

          {/* Store name */}
          <td className="whitespace-nowrap px-2 text-left text-xs text-gray-500 sm:pl-0">
            {store.store_name}
          </td>

          {/* Street name */}
          <td className="whitespace-nowrap px-2 text-left text-xs text-gray-500">
            {store.address_1}
          </td>

          {/* Postal code */}
          <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
            {store.postal_code}
          </td>

          {/* City */}
          <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
            {store.city}
          </td>

          {/* Country */}
          <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
            {store.country_code}
          </td>

          {/* Language */}
          <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
            {store.language}
          </td>

          {/* Updated at */}
          <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
            {store.updated_at}
          </td>

          {/* Openings date */}
          <td className="whitespace-nowrap px-2 text-left text-xs text-gray-900">
              {dateHelperWeirdFromBackend(store.opening_date)}
          </td>

          {/* Storeformat */}
          <td className="whitespace-nowrap w-52 px-2 text-left text-xs text-gray-900">
            <div className="inline-flex items-center rounded-full bg-gray-200 px-2 text-xs font-medium text-gray-900">
              {store.store_format ? store.store_format.name : "no format"}
            </div>
          </td>
        </tr>
      </Fragment>
    )
  );
  //#endregion
}
