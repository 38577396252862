//#region IMPORTS
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  ProductVersion,
  ProductVersionFilters,
  ProductVersionParams,
  ProductVersionsState,
} from "../../models/productVersion";
import { RootState } from "../../store/configureStore";
import { toast } from "react-toastify";
import getAxiosParams from "../../helpers/getAxiosParams";
import agent from "../../api/agent";
//#endregion

//#region INIT
const initialExtraState: ProductVersionsState = {
  product_versions_loaded: false,
  filters_loaded: false,
  filters: initFilters(),
  product_version_params: initParams(),
  metaData: undefined,
  status: "idle",
};

// Set initial query parameters
function initParams(): ProductVersionParams {
  return {
    page: 1,
    limit: 25,
  };
}

// Set all filter options
function initFilters(): ProductVersionFilters {
  return {
    promo_status: [],
    produced_by: [],
    language: [],
    promo_id: [],
    country: [],
  };
}
//#endregion

//#region CREATE ADAPTER
const productVersionsAdapter = createEntityAdapter<ProductVersion>();
//#endregion

//#region READ
// Fetch All
export const fetchProductVersionsAsync = createAsyncThunk<
  ProductVersion[],
  void,
  { state: RootState }
>("productVersions/fetchProductVersionssAsync", async (_, thunkAPI) => {
  const productVersionParams = getAxiosParams(
    thunkAPI.getState().productVersions.product_version_params
  );
  try {
    const { data, meta } = await agent.ProductVersions.list(
      productVersionParams
    );
    thunkAPI.dispatch(setProductVersionMetaData(meta));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});

// Fetch detail
export const fetchProductVersionAsync = createAsyncThunk<
  ProductVersion,
  number,
  { state: RootState }
>("productVersions/fetchProductVersionAsync", async (id) => {
  try {
    return await agent.ProductVersions.detail(id);
  } catch (error) {
    toast.error(`${error}`);
  }
});

// Fetch all filters
export const fetchFilters = createAsyncThunk(
  "productVersions/fetchFilters",
  async (_, thunkAPI) => {
    try {
      return agent.ProductVersions.fetchFilters();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);
//#endregion

//#region DELETE
export const deleteProductVersionAsync = createAsyncThunk<
  number,
  { id: number }
>("productVersions/deleteProductVersionAsync", async ({ id }, thunkAPI) => {
  try {
    await agent.ProductVersions.delete(id);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});
//#endregion

//#region CREATE REDUCER SLICE
export const productVersionsSlice = createSlice({
  name: "productVersions",
  initialState:
    productVersionsAdapter.getInitialState<ProductVersionsState>(
      initialExtraState
    ),
  reducers: {
    setProductVersionParams: (state, action) => {
      state.product_versions_loaded = false;
      state.product_version_params = {
        ...state.product_version_params,
        ...action.payload,
        page: 1,
      };
    },
    clearProductVersionParams: (state) => {
      state.product_versions_loaded = false;
      state.product_version_params = initParams();
    },
    clearProductVersionParam: (state, action) => {
      const newParmams = { ...state.product_version_params };
      delete newParmams[action.payload];
      state.product_versions_loaded = false;
      state.product_version_params = newParmams;
    },
    setProductVersionPageNumber: (state, action) => {
      state.product_versions_loaded = false;
      state.product_version_params = {
        ...state.product_version_params,
        ...action.payload,
      };
    },
    setProductVersionMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    resetProductVersionParams: (state) => {
      state.product_versions_loaded = false;
      state.product_version_params = initParams();
    },
    clearProductVersions: () =>
      productVersionsAdapter.getInitialState<ProductVersionsState>(
        initialExtraState
      ),
    setProductVersion: (state, action) =>
      productVersionsAdapter.upsertOne(state, action.payload),
  },
  extraReducers: (builder) => {
    // FETCH productversions
    builder.addCase(fetchProductVersionsAsync.pending, (state) => {
      state.status = "pendingFetchProductVersions";
    });
    builder.addCase(fetchProductVersionsAsync.fulfilled, (state, action) => {
      productVersionsAdapter.setAll(state, action.payload);
      state.status = "idle";
      state.product_versions_loaded = true;
    });
    builder.addCase(fetchProductVersionsAsync.rejected, (state) => {
      state.status = "idle";
    });

    // FETCH productversion by id
    builder.addCase(fetchProductVersionAsync.pending, (state) => {
      state.status = "pendingFetchProductVersion";
    });
    builder.addCase(fetchProductVersionAsync.fulfilled, (state, action) => {
      productVersionsAdapter.upsertOne(state, action.payload);
      state.status = "idle";
    });
    builder.addCase(fetchProductVersionAsync.rejected, (state) => {
      state.status = "idle";
    });

    // FETCH filters
    builder.addCase(fetchFilters.pending, (state) => {
      state.status = "pendingFetchProductVersionFilters";
    });
    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      state.filters.produced_by = action.payload.producers;
      state.filters.language = action.payload.languages;
      state.filters.country = action.payload.countries;
      state.filters.promo_id = action.payload.promoIds;
      state.filters.promo_status = action.payload.promoStatuses;
      state.filters_loaded = true;
      state.status = "idle";
    });
    builder.addCase(fetchFilters.rejected, (state) => {
      state.status = "idle";
    });

    // DELETE productversion
    builder.addCase(deleteProductVersionAsync.pending, (state) => {
      state.status = "PendingDeleteProductVersion";
    });
    builder.addCase(deleteProductVersionAsync.fulfilled, (state, action) => {
      productVersionsAdapter.removeOne(state, action.payload);
      state.status = "idle";
    });
    builder.addCase(deleteProductVersionAsync.rejected, (state) => {
      state.status = "idle";
    });
  },
});
//#endregion

//#region NORMALISED STATE
export const productVersionsSelectors = productVersionsAdapter.getSelectors(
  (state: RootState) => state.productVersions
);
//#endregion

//#region EXPORT
export const {
  setProductVersionPageNumber,
  setProductVersionMetaData,
  clearProductVersionParams,
  resetProductVersionParams,
  clearProductVersionParam,
  setProductVersionParams,
  clearProductVersions,
  setProductVersion,
} = productVersionsSlice.actions;
//#endregion
