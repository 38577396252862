//#region IMPORTS
import { ReactNode } from "react";
//#endregion

//#region INTERFACE
interface Props {
  children: ReactNode;
  classNamesExtensions?: string;
}
//#endregion

export default function MainContainer({
  children,
  classNamesExtensions,
}: Props) {
  //#region RENDER
  return (
    <div
      className={`bg-white px-8${
        classNamesExtensions ? ` ${classNamesExtensions}` : ""
      }`}
    >
      {children}
    </div>
  );
  //#endregion
}
